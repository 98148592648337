import { Menu, MenuProps } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PeopleIcon from '../../svgs/menu/PeopleIcon';
import SalesheetIcon from '../../svgs/menu/SalesheetIcon';
import FilmIcon from '../../svgs/menu/FilmIcon';
import BillingIcon from '../../svgs/menu/BillingIcon';
import CompanyIcon from '../../svgs/menu/CompanyIcon';
import LicenseIcon from '../../svgs/menu/LicenseIcon';
import RightIcon from '../../svgs/menu/RightIcon';
import SupplierIcon from '../../svgs/menu/SupplierIcon';
import { getModulesByRoles } from '../../../utils/global';
import AuditIcon from '../../svgs/menu/AuditIcon';

type Props = {
  currentUser?: any;
};

const MainMenu: React.FC<Props> = ({ currentUser }) => {
  const { pathname } = useLocation();
  const roles = currentUser.roles || [];
  const accessList: string[] = getModulesByRoles(roles);
  let selectedKey = pathname;
  let openKey = '';

  if (selectedKey.includes('/users')) {
    selectedKey = '/users';
  } else if (selectedKey.includes('/billings')) {
    selectedKey = '/billings';
  } else if (selectedKey.includes('/rights')) {
    selectedKey = '/rights';
  } else if (selectedKey.includes('/films')) {
    selectedKey = '/films';
  } else if (selectedKey.includes('/contracts')) {
    selectedKey = '/contracts';
  } else if (selectedKey.includes('/licenses')) {
    selectedKey = '/licenses';
  } else if (selectedKey.includes('/customers')) {
    selectedKey = '/customers';
  } else if (selectedKey.includes('/suppliers')) {
    selectedKey = '/suppliers';
  } else if (selectedKey.includes('/salesheets')) {
    selectedKey = '/salesheets';
  } else if (selectedKey.includes('/audit-logs')) {
    selectedKey = '/audits';
  } else {
    selectedKey = '/';
  }

  const onSelect = (item: any) => {
    if (item.key === '/users') {
      openKey = '/users';
    } else if (item.key === '/billings') {
      openKey = '/billings';
    } else if (item.key === '/rights') {
      openKey = '/rights';
    } else if (item.key === '/films') {
      openKey = '/films';
    } else if (item.key === '/contracts') {
      openKey = '/contracts';
    } else if (item.key === '/licenses') {
      openKey = '/licenses';
    } else if (item.key === '/customers') {
      openKey = '/customers';
    } else if (item.key === '/suppliers') {
      openKey = '/suppliers';
    } else if (item.key === '/salesheets') {
      openKey = '/salesheets';
    } else if (item.key === '/audits') {
      openKey = '/audits';
    } else {
      openKey = item.key;
    }
  };

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label: !children ? <Link to={`${key}`}>{label}</Link> : label,
      type,
    } as MenuItem;
  }

  let items: any[] = [
    accessList?.includes('/films') ? getItem('Films Bought', '/films', <FilmIcon />) : null,
    accessList?.includes('/billings') ? getItem('Billings', '/billings', <BillingIcon />) : null,
    accessList?.includes('/customers') ? getItem('Customers', '/customers', <CompanyIcon />) : null,
    accessList?.includes('/licenses') ? getItem('License Sold', '/licenses', <LicenseIcon />) : null,
    accessList?.includes('/rights') ? getItem('Rights', '/rights', <RightIcon />) : null,
    accessList?.includes('/suppliers') ? getItem('Suppliers', '/suppliers', <SupplierIcon />) : null,
    accessList?.includes('/users') ? getItem('Users', '/users', <PeopleIcon />) : null,
    accessList?.includes('/salesheets') ? getItem('Salesheets', '/salesheets', <SalesheetIcon />) : null,
    accessList?.includes('/audits') ? getItem('Audit Logs', '/audits', <AuditIcon />) : null,
  ];

  return (
    <Menu
      className="main-menu"
      triggerSubMenuAction="hover"
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      onSelect={onSelect}
      defaultOpenKeys={[openKey]}
      items={items}
    />
  );
};

export default MainMenu;
