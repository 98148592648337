import { DatePicker, Form, Input, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CustomerFilm } from '../../../../interfaces/film';
import { setStartTime, setEndTime, convertDateTimeToTimestamp, getCurrencyCode } from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import { NumericFormat } from 'react-number-format';
import { useQuery } from 'react-query';
import { getCustomersList } from '../../../../api/customers';
import BackButton from '../../buttons/BackButton';
import { BillingI } from '../../../../interfaces/billing';
import { doGetFilmsList } from '../../../../api/films';
import currencyCodes from 'currency-codes';

type Props = {
  billingId?: number;
  billing?: BillingI;
  handleSubmit(_: any): void;
};

const BillingForm: React.FC<Props> = ({ billing, billingId, handleSubmit }) => {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState('SGD');

  const { data: customerFilm, isFetching: isCustomerFilmFetching } = useQuery(['customer-films', 'list'], () =>
    getCustomersList({}),
  );
  const { data: films, isFetching: isFilmFetching } = useQuery(['films', 'list'], () => doGetFilmsList({}));

  const handleOnFinish = (values: any) => {
    const newValues: any = values;
    const billDate = convertDateTimeToTimestamp(setStartTime(values.billDate));
    const billPeriod = convertDateTimeToTimestamp(setEndTime(values.billPeriod));
    newValues.billDate = billDate;
    newValues.billPeriod = billPeriod;
    handleSubmit(newValues);
  };

  useEffect(() => {
    if (billing && billing.id === billingId && !isCustomerFilmFetching) {
      form.setFieldsValue({
        ...billing,
        billDate: billing?.billDate ? dayjs(billing.billDate) : null,
        billPeriod: billing?.billPeriod ? dayjs(billing.billPeriod) : null,
      });
    }
  }, [billing, isCustomerFilmFetching]);

  return (
    <div className="billing-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ currency: currency }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Customer" name="customerId" rules={[{ required: true, message: 'required' }]}>
              <Select placeholder="Required" showSearch optionFilterProp="children" loading={isCustomerFilmFetching}>
                {customerFilm?.data?.map((item: CustomerFilm) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Film" name="filmId">
              <Select placeholder="Required" showSearch optionFilterProp="children" loading={isFilmFetching}>
                {films?.data?.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Amount">
              <Space.Compact>
                <Form.Item name="currency" style={{ width: 100, marginBottom: 0 }}>
                  <Select
                    value={currency}
                    onChange={(value) => setCurrency(value)}
                    showSearch
                    optionFilterProp="children"
                  >
                    {currencyCodes.data.map((curr: any) => (
                      <Select.Option key={curr.code} value={getCurrencyCode(curr.code)}>
                        {getCurrencyCode(curr.code)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="amount" style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
                  <NumericFormat
                    className="ant-input"
                    placeholder="Required"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
            <Form.Item name="billDate" label="Bill Date">
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item name="billPeriod" label="Bill Period">
              <DatePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item name="remark" label="Remark">
              <Input.TextArea rows={3} placeholder="Optional" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={billingId ? 'Update' : 'Create'} icon={<CheckCircleIcon />} htmlType="submit" />
            <BackButton label={'Cancel'} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BillingForm;
