import { Form, Input, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { RightI } from '../../../../interfaces/right';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import { useQuery } from 'react-query';
import { getRightsList } from '../../../../api/rights';
import BackButton from '../../buttons/BackButton';

type Props = {
  isFetching?: boolean;
  rightId?: number;
  right?: RightI;
  handleSubmit(_: any): void;
};

type DBRights = { id: number; name: string; remarks: string | null; parentId: number; createdById: number };

type ParentRights = { id: number; title: string; value: number; parentId: number };

const RightForm: React.FC<Props> = ({ right, rightId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();
  const [parentsList, setParentsList] = useState<RightI[]>([]);

  const { data: rights, isLoading: isRightFetching } = useQuery(['rights', 'list'], () => getRightsList({}));

  const handleOnFinish = (values: RightI) => {
    handleSubmit(values);
  };

  useEffect(() => {
    if (right && right.id === rightId) {
      form.setFieldsValue(right);
    }
  }, [right]);

  useEffect(() => {
    if (rights && rights.data) {
      const newData: ParentRights[] = rights.data.reduce((accumulator: ParentRights[], currentValue: DBRights) => {
        if (currentValue.id === rightId) return accumulator;
        accumulator.push({
          id: currentValue.id,
          title: currentValue.name,
          value: currentValue.id,
          parentId: currentValue.parentId,
        });
        return accumulator;
      }, []);
      const newLocation = newData?.map((location: any) => {
        return { ...location, children: null };
      });

      _(newLocation).forEach((f) => {
        const child = _(newLocation)
          .filter((g) => g.parentId === f.id)
          .value();

        if (child.length !== 0) {
          f.children = child;
        } else {
          delete f.children;
        }
      });

      const result = _(newLocation)
        .filter((f) => f.parentId === null)
        .value();

      setParentsList(result);
    }
  }, [rights]);

  return (
    <div className="right-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
              <Input type="text" placeholder="Required" />
            </Form.Item>
            <Form.Item label="Parent" name="parentId">
              <TreeSelect
                style={{ width: '100%' }}
                loading={isRightFetching}
                treeData={parentsList || []}
                placeholder="Optional"
                treeDefaultExpandAll
                treeNodeFilterProp="title"
              />
            </Form.Item>
            <Form.Item label="Remarks" name="remarks">
              <Input.TextArea rows={3} placeholder="Optional" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={rightId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <BackButton label={'Cancel'} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RightForm;
