import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, MenuProps } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import { useQueryClient } from 'react-query';
import { TOKEN_NAME } from '../../constants';
import useAuth from '../../hooks/useAuth';
import Notifications from './Notifications';
const { Header } = Layout;

type Props = {
  currentUser?: any;
};

const HeaderMain: React.FC<Props> = ({ currentUser }) => {
  const { setAuth }: any = useAuth();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    Cookies.remove(TOKEN_NAME);
    setAuth(undefined);
    queryClient.invalidateQueries();
    window.location.href = '/login';
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Button onClick={handleLogout}>Log Out</Button>,
    },
  ];

  const displayName = currentUser?.lastName
    ? `${currentUser?.firstName} ${currentUser?.lastName}`
    : currentUser?.firstName;

  return (
    <Header className="site-header">
      <div className="logo"></div>
      <div className="account-info">
        {currentUser.role !== 'super_admin' && <Notifications />}
        <div className="avatar">
          <img
            src={`https://ui-avatars.com/api/?length=1&name=${
              currentUser?.firstName ? `${currentUser?.firstName} ${currentUser?.lastName}` : currentUser?.email
            }&size=32&font-size=0.4&bold=true&background=5f5f5f&color=FFFFFF&rounded=true`}
            alt=""
          />
        </div>
        <div className="info">
          <div className="fullname">{displayName}</div>
          <Dropdown overlayClassName="header-info-dropdown" menu={{ items }}>
            <div className="email">
              {currentUser?.email} <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderMain;
