import React, { useEffect, useState } from 'react';
import { FilmI } from '../../interfaces/film';
import dayjs from 'dayjs';
import { DATE_FORMAT_FRONTEND } from '../constants';
import './FilmDetails.scss';
import { Link } from 'react-router-dom';
import PrimaryButton from './buttons/PrimaryButton';
import EditIcon from '../svgs/EditIcon';
import DangerButton from './buttons/DangerButton';
import BackButton from './buttons/BackButton';
import CancelIcon from '../svgs/CancelIcon';
import DeleteForeverIcon from '../svgs/DeleteForeverIcon';
import defaultPoster from '../../images/defaultPoster.png';
import { RightI } from '../../interfaces/right';
import { AccessControlI } from '../../interfaces';
import { CurrentUserI } from '../../interfaces/auth';
import {
  getUsername,
  handleGetCoShareValue,
  handleGetCountriesWithSEA,
  hasCommonElementArray,
} from '../../utils/global';
// import { Tooltip } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  film?: FilmI;
  hideActionButtons: boolean | false;
  hideCreatedAt?: boolean;
  handleDelete?: () => void;
  access?: AccessControlI;
  currentUser?: CurrentUserI;
};

const FilmDetailsComponent: React.FC<Props> = ({
  film,
  hideActionButtons,
  handleDelete,
  access,
  currentUser,
  hideCreatedAt = true,
}) => {
  const [newFilmState, setNewFilmState] = useState<FilmI>();
  useEffect(() => {
    if (film) {
      const newfilm = film;
      newfilm.coShare = handleGetCoShareValue(film, currentUser);
      if (film.territories) {
        const newTerritories = film.territories?.map((territory: any) => {
          const newCountries = territory?.isWorldwide
            ? ['WORLDWIDE']
            : handleGetCountriesWithSEA(territory?.name || []);
          return {
            ...territory,
            name: newCountries,
          };
        });
        newfilm.territories = newTerritories;
      }
      setNewFilmState(newfilm);
    }
  }, [film]);

  return (
    <div className="film-details-shared">
      {/* <div className="banner-section">
        <img
          src={
            newFilmState?.panelImage && Object.keys(newFilmState?.panelImage).length > 0
              ? newFilmState?.panelImage[0].s3Url
              : defaultImage
          }
          alt="banner"
        />
      </div> */}
      <div className="details-section">
        <div className="details-content-wrap">
          <div className="wrapper">
            <div className="film-poster">
              <img
                src={
                  newFilmState?.posterImage && Object.keys(newFilmState?.posterImage).length > 0
                    ? newFilmState?.posterImage[0].s3Url
                    : defaultPoster
                }
                alt="poster"
              />
            </div>
            <div className="page-details-content">
              <div className="page-content-wrapper">
                <div className="film-title">
                  <h2 className="text-uppercase">{newFilmState?.title}</h2>
                  {newFilmState?.titleCN && <h2>{newFilmState.titleCN}</h2>}
                </div>
                <div className="details-container">
                  {hideCreatedAt && (
                    <div className="item">
                      <label>Created At</label>
                      <span className="value">{dayjs(newFilmState?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
                    </div>
                  )}
                  {hideCreatedAt && (
                    <>
                      <div className="item">
                        <label>Supplier</label>
                        <span className="value">{newFilmState?.supplier?.name}</span>
                      </div>
                      <div className="item">
                        <label>License Fee</label>
                        <span className="value">{newFilmState?.licenseFee}</span>
                      </div>
                      <div className="item">
                        <label>Co Share</label>
                        <span className="value">{newFilmState?.coShare?.join(', ')}</span>
                      </div>
                      <div className="item">
                        <label>Date of Agreement</label>
                        <span className="value">
                          {newFilmState?.dateOfAgreement
                            ? dayjs(newFilmState?.dateOfAgreement).format(DATE_FORMAT_FRONTEND)
                            : null}
                        </span>
                      </div>
                      <div className="item">
                        <label>Overall License Period</label>
                        <span className="value">
                          {newFilmState?.licenseStartDate
                            ? dayjs(newFilmState?.licenseStartDate).format(DATE_FORMAT_FRONTEND)
                            : null}{' '}
                          -{' '}
                          {newFilmState?.licenseEndDate
                            ? dayjs(newFilmState?.licenseEndDate).format(DATE_FORMAT_FRONTEND)
                            : null}
                        </span>
                      </div>
                    </>
                  )}
                  <div className="item">
                    <label>Genre</label>
                    <span className="value text-capitalize">
                      {newFilmState?.genre && newFilmState?.genre?.join(', ')}
                    </span>
                  </div>
                  <div className="item">
                    <label>Director</label>
                    <span className="value">{newFilmState?.director}</span>
                  </div>
                  <div className="item">
                    <label>Cast</label>
                    <span className="value">{newFilmState?.cast}</span>
                  </div>
                  {hideCreatedAt && (
                    <div className="item">
                      <label>Box Office</label>
                      <span className="value">{newFilmState?.boxOffice}</span>
                    </div>
                  )}
                  <div className="item">
                    <label>Year</label>
                    <span className="value">{newFilmState?.year}</span>
                  </div>
                  <div className="item">
                    <label>Synopsis</label>
                    <span className="value">{newFilmState?.synopsis}</span>
                  </div>
                  <div className="item">
                    <label>Languages</label>
                    <span className="value">{newFilmState?.languages}</span>
                  </div>
                  <div className="item">
                    <label>Trailer</label>
                    <span className="value">
                      <Link to={newFilmState?.trailer ?? '/'} target="_blank">
                        {newFilmState?.trailer}
                      </Link>
                    </span>
                  </div>
                  <div className="item">
                    <label>Coutry of Origin</label>
                    <span className="value">{newFilmState?.countryOfOrigin}</span>
                  </div>
                  {hideCreatedAt && (
                    <>
                      <div className="item">
                        <label>Contract Reference Number</label>
                        <span className="value">{newFilmState?.contractReferenceNumber}</span>
                      </div>
                      <div className="item">
                        <label>Territories</label>
                        <span className="value">
                          <ul>
                            {newFilmState?.territories?.map((territory: any) => (
                              <li key={territory.id}>
                                <h2 style={{ fontSize: 14, marginBottom: 5 }}>
                                  {territory?.name ? territory.name.join(', ') : ''}{' '}
                                  {territory?.isWorldwide && territory?.excludedTerritories.length > 0 && (
                                    // <Tooltip
                                    //   placement="top"
                                    //   title={
                                    //     <>
                                    //       <div>Excluded Regions</div>
                                    //       <div>
                                    //         {handleGetCountriesWithSEA(territory.excludedTerritories).join(', ')}
                                    //       </div>
                                    //     </>
                                    //   }
                                    //   key={index}
                                    // >
                                    //   <InfoCircleOutlined />
                                    // </Tooltip>
                                    <div>
                                      Excluded Regions (
                                      <span style={{ color: 'blue', wordWrap: 'break-word' }}>
                                        {handleGetCountriesWithSEA(territory.excludedTerritories).join(', ')}
                                      </span>
                                      )
                                    </div>
                                  )}
                                </h2>
                                {hasCommonElementArray(['FINANCE', 'DATABASE'], currentUser?.roles || []) && (
                                  <p style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>{territory.remarks}</p>
                                )}
                                <div>
                                  {territory?.exclusiveRights && Object.keys(territory?.exclusiveRights).length > 0 && (
                                    <div className="item2">
                                      <label>Exclusive Rights</label>
                                      <ul>
                                        {territory?.exclusiveRights?.map((right: RightI) => (
                                          <li style={{ marginBottom: 5 }} key={right.id}>
                                            {right.name}:{' '}
                                            {right?.availableDateRanges
                                              ? right?.availableDateRanges
                                              : right?.startDate && right?.endDate
                                              ? `${dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} - ${dayjs(
                                                  right?.endDate,
                                                ).format(DATE_FORMAT_FRONTEND)}`
                                              : '-'}
                                            {right.remarks && ` Remarks: ${right.remarks}`}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  {territory?.nonExclusiveRights &&
                                    Object.keys(territory?.nonExclusiveRights).length > 0 && (
                                      <div className="item2">
                                        <label>Non Exclusive Rights</label>
                                        <ul>
                                          {territory?.nonExclusiveRights?.map((right: RightI) => (
                                            <li style={{ marginBottom: 5 }} key={right.id}>
                                              {right.name}:{' '}
                                              {right?.availableDateRanges
                                                ? right?.availableDateRanges
                                                : right?.startDate && right?.endDate
                                                ? `${dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} - ${dayjs(
                                                    right?.endDate,
                                                  ).format(DATE_FORMAT_FRONTEND)}`
                                                : '-'}
                                              {right.remarks && ` Remarks: ${right.remarks}`}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </span>
                      </div>
                    </>
                  )}
                  {hideCreatedAt && (
                    <>
                      <div className="item">
                        <label>Created By</label>
                        <span className="value">
                          {newFilmState?.createdBy ? getUsername(newFilmState?.createdBy) : '-'}
                        </span>
                      </div>
                      <div className="item">
                        <label>Updated At</label>
                        <span className="value">
                          {newFilmState?.updatedBy ? dayjs(newFilmState?.updatedAt).format(DATE_FORMAT_FRONTEND) : '-'}
                        </span>
                      </div>
                      <div className="item">
                        <label>Updated By</label>
                        <span className="value">
                          {newFilmState?.updatedBy ? getUsername(newFilmState?.updatedBy) : '-'}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {hideActionButtons && (
                <div className="page-content-footer">
                  {access?.isCanUpdate && (
                    <Link to={`/films/${newFilmState?.id}/edit`}>
                      <PrimaryButton label="Edit" icon={<EditIcon />} />
                    </Link>
                  )}
                  {access?.isCanDelete && handleDelete && (
                    <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />
                  )}
                  <BackButton label="Cancel" icon={<CancelIcon />} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilmDetailsComponent;
