import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const FileCopyIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height || 24}px`}
      viewBox="0 -960 960 960"
      width={`${width || 24}px`}
      fill={color || 'currentColor'}
    >
      <path d="M780-160H260q-24 0-42-18t-18-42v-640q0-24 18-42t42-18h348l232 232v468q0 24-18 42t-42 18ZM578-662v-198H260v640h520v-442H578ZM140-40q-24 0-42-18t-18-42v-619h60v619h498v60H140Zm120-820v198-198 640-640Z" />
    </svg>
  );
};
export default FileCopyIcon;
