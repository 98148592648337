import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateRight, useGetRightDetails } from '../../api/rights';
import RightForm from '../../shared/components/forms/right/Form';

const RightUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetRightDetails(Number(id));

  const { mutateAsync: updateMutate } = useMutation(doUpdateRight, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['rights', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`type-of-rights/${id}`, undefined],
      });
      message.info('Update right successfully!');
      navigate(`/rights`, { replace: true });
    },
    onError: () => {
      message.error('Update right failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="right-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Right</h2>
            </div>
            <RightForm handleSubmit={handleSubmit} right={data} rightId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default RightUpdatePage;
