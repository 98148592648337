import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { CustomerI } from '../interfaces/customer';
import { QueryObserverResult } from 'react-query';

export const useGetCustomersList = () => useFetch<GetListResponse<CustomerI>>('customers');

export const useGetCustomerDetails = (id: number) =>
  useFetch<CustomerI & { refetch: QueryObserverResult<any, unknown> }>(`customers/${id}`, {}, { refetchOnMount: true });

export const useCreateCustomer = (updater: (oldData: CustomerI[], newData: CustomerI) => CustomerI[]) => {
  return usePost<CustomerI[], CustomerI>(pathToUrl('customers'), undefined, updater);
};

export const getCustomersList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`customers?${newParams}`);
  return response;
};

export const getCustomersFilmList = async ({
  start,
  count,
  filter,
}: {
  start?: number;
  count?: number;
  filter?: any;
}) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`customers/films?${newParams}`);
  return response;
};

export const doCreateCustomer = async (values: CustomerI) => {
  const { data: response }: any = await api.post('customers', values);
  return response.data;
};

export const doUpdateCustomer = async ({ id, ...values }: { id: string; values: any }) => {
  const { data: response }: any = await api.patch(`customers/${id}`, values);
  return response.data;
};

export const doDeleteCustomer = async (id: number) => {
  const { data: response }: any = await api.delete(`customers/${id}`);
  return response.data;
};
