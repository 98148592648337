import { Form, Spin } from 'antd';
import React, { useState } from 'react';
import ImportXLSX from '../../uploads/ImportXLSX';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import CancelIcon from '../../../svgs/CancelIcon';

type Props = {
  handleSubmit(_: any): void;
  handleCancel(): void;
  form?: any;
  isLoading?: boolean;
};

const FilmImportForm: React.FC<Props> = ({ form, isLoading, handleCancel, handleSubmit }) => {
  const [files, setFiles] = useState<any[]>([]);

  const onCancel = () => {
    setFiles([]);
    handleCancel();
  };

  const handleOnFinish = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    handleSubmit(formData);
    onCancel();
  };

  return (
    <Spin spinning={isLoading} className="upload-unit-form-container">
      <Form form={form} requiredMark colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-header">
            <div className="title">Import Films</div>
          </div>
          <div className="form-content">
            <Form.Item rules={[{ required: true, message: 'is required' }]}>
              <ImportXLSX name="file" documents={files} setDocuments={setFiles} multiple={false} maxCount={2} />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton htmlType="submit" label={'Import'} icon={<CheckCircleIcon />} />
            <SecondaryButton label="Cancel" onClick={onCancel} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default FilmImportForm;
