import { pathToUrl } from './../utils/router';
import { User } from './../interfaces/user';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetUsersList = () => useFetch<GetListResponse<User>>('users');

export const useGetUser = (id: number) => useFetch<User>(`users/${id}`);

export const useCreateUser = (updater: (oldData: User[], newData: User) => User[]) => {
  return usePost<User[], User>(pathToUrl('users'), undefined, updater);
};

export const getUsersList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`users?${newParams}`);
  return response;
};

export const createUser = async (values: User) => {
  const { data: response }: any = await api.post('users', values);
  return response.data;
};

export const updateUser = async ({ id, ...values }: { id: string; values: User }) => {
  const { data: response }: any = await api.patch(`users/${id}`, values);
  return response.data;
};

export const deleteUser = async (id: number) => {
  const { data: response }: any = await api.delete(`users/${id}`);
  return response.data;
};
