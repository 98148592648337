import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateBilling, useGetBillingDetails } from '../../api/billings';
import BillingForm from '../../shared/components/forms/billing/Form';

const BillingUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data: billing, isLoading } = useGetBillingDetails(Number(id));

  const { mutateAsync: updateMutate } = useMutation(doUpdateBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['billings', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`billings/${id}`, undefined],
      });
      message.info('Update billing successfully!');
      navigate(`/billings`);
    },
    onError: () => {
      message.error('Update billing failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="billing-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Billing</h2>
            </div>
            <BillingForm handleSubmit={handleSubmit} billing={billing} billingId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default BillingUpdatePage;
