import { Button, Dropdown, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { doDeleteRight, getRightsList } from '../../api/rights';
import { User } from '../../interfaces/user';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import RightsFilterForm from '../../shared/components/forms/right/Filter';
import RightsTable from '../../shared/components/tables/Rights';
import { PAGE_SIZE } from '../../shared/constants';
import AddCircleOutlineIcon from '../../shared/svgs/AddCircleOutlineIcon';
import { deleteEmptyChildren, handleAccessControl, handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';

const RightsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles, 'rights');
  const queryClient = useQueryClient();
  const [rights, setRights] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const start = Number(urlSearch.get('start')) ?? 0;
    const count = Number(urlSearch.get('count')) ?? PAGE_SIZE;
    const search = urlSearch.get('filter[name]');

    return {
      start,
      count,
      filter: {
        name: search,
        root: true,
      },
    };
  }, [urlSearch]);
  const { data, isLoading } = useQuery(['rights', 'list', queryParams], () => getRightsList(queryParams));

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: any) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ ...queryParams, start: offset, count: params.per_page });
  };

  const { mutateAsync: mutationDelete } = useMutation(doDeleteRight, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['rights', 'list'],
      });
    },
  });

  const handleClickDelete = (user: User) => {
    mutationDelete(user.id);
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: { root: true, ...values } });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: { root: true } });
  };

  useEffect(() => {
    if (data) {
      const newData = data?.data?.map((d: any) => {
        return deleteEmptyChildren(d || []);
      });
      setRights(newData);
    } else {
      setRights([]);
    }
  }, [data]);

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/rights/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/rights/${record?.id}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanDelete
                ? {
                    key: '3',
                    label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
                  }
                : null,
            ],
          }}
        />
      </span>
    );
  };

  return (
    <div className="rights-container">
      <div className="page-container">
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            {access.isCanCreate && (
              <Link to="/rights/new">
                <PrimaryButton label="Create Right" icon={<AddCircleOutlineIcon />} />
              </Link>
            )}
          </div>
        </div>
        <div className="page-content">
          <RightsFilterForm
            handleFilter={handleFilterSubmit}
            handleReset={handleResetFilter}
            queryParams={queryParams}
          />
          <RightsTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={rights}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default RightsPage;
