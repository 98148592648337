import { Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EnterPasswordForm from './components/EnterPassword';
import { useMutation } from 'react-query';
import { doCustomerEnterPassword } from '../../api/salesheets';
import CustomerSalesheet from './components/CustomerSalesheet';
import '../../global.scss';
import './SalesheetDetailsForCustomer.scss';

const SalesheetDetailsForCustomerPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [salesheet, setSalesheet] = useState<any[]>([]);

  const { mutate: enterPasswordMutate } = useMutation(doCustomerEnterPassword, {
    onSuccess: async (data: any) => {
      console.log('data', data);
      setSalesheet(data?.salesSheetDetails || []);
    },
  });

  const onEnterPasswordSubmit = async (values: { password: string }) => {
    enterPasswordMutate({ id: id || '', body: values });
  };

  return (
    <Spin spinning={false}>
      <div id="salesheets-customer-details-container">
        <div className="page-container">
          {salesheet && Object.keys(salesheet).length > 0 ? (
            <CustomerSalesheet salesheet={salesheet} />
          ) : (
            <EnterPasswordForm onEnterPasswordSubmit={onEnterPasswordSubmit} />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default SalesheetDetailsForCustomerPage;
