import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../constants';
import { useGetProfile } from '../../api/auth';
import MainLayout from './layout/MainLayout';
import { Spin } from 'antd';
import { handleCheckAuthorize } from '../../utils/global';

const RequireAuth: React.FC = () => {
  const { auth }: any = useAuth();
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  let selectedKey = location?.pathname || '';
  const profile = token ? useGetProfile() : undefined;

  const handleNavigationAtRootPath = (roles: string[]) => {
    if (roles.includes('SUPER_ADMIN')) {
      return <Navigate to="/users" state={{ from: location }} replace />;
    } else if (roles.includes('FINANCE') || roles.includes('DATABASE')) {
      return <Navigate to="/films" state={{ from: location }} replace />;
    } else if (roles.includes('SALE')) {
      return <Navigate to="/salesheets" state={{ from: location }} replace />;
    } else return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  };
  if (token) {
    if ((auth && Object.values(auth).length > 0) || (profile && profile.isSuccess === true)) {
      const isAuthorize: boolean = handleCheckAuthorize(profile?.data?.roles || auth?.roles, selectedKey);
      if (selectedKey === '/') {
        return handleNavigationAtRootPath(profile?.data?.roles || auth?.roles);
      }
      if (isAuthorize) {
        return <MainLayout currentUser={profile?.data || auth} />;
      }
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    } else {
      return (
        <div className="loading-screen">
          <Spin spinning={true}> </Spin>;
        </div>
      );
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
