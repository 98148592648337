import { FilmI } from './../interfaces/film';
import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';

export const useGetFilmsList = () => useFetch<GetListResponse<FilmI>>('films');

export const useGetFilmDetails = (id: number) => useFetch<FilmI>(`films/${id}`, {}, { refetchOnMount: true });

export const useCreateFilm = (updater: (oldData: FilmI[], newData: FilmI) => FilmI[]) => {
  return usePost<FilmI[], FilmI>(pathToUrl('films'), undefined, updater);
};

export const doGetFilmsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`films?${newParams}`);
  return response;
};

export const doGetSalesheetFilmsList = async ({
  start,
  count,
  filter,
}: {
  start?: number;
  count?: number;
  filter?: any;
}) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`films/list?${newParams}`);
  return response;
};

export const doCreateFilm = async (values: FilmI) => {
  const { data: response }: any = await api.post('films', values);
  return response.data;
};

export const doUpdateFilm = async ({ id, ...values }: { id: string; values: FilmI }) => {
  const { data: response }: any = await api.patch(`films/${id}`, values);
  return response.data;
};

export const doDeleteFilm = async (id: number) => {
  const { data: response }: any = await api.delete(`films/${id}`);
  return response.data;
};

export const doGetFilmDetails = async (id: number) => {
  const { data }: any = await api.get(`films/${id}`);
  return data;
};

export const doImportFilms = async (formData: any) => {
  const { data: response }: any = await api.postFormData('films/import', formData);
  return response;
};
