import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateSupplier, useGetSupplierDetails } from '../../api/suppliers';
import SupplierForm from '../../shared/components/forms/supplier/Form';

const SupplierUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetSupplierDetails(Number(id));

  const { mutateAsync: updateMutate } = useMutation(doUpdateSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['suppliers', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`suppliers/${id}`, undefined],
      });
      message.info('Update supplier successfully!');
      navigate(`/suppliers`, { replace: true });
    },
    onError: () => {
      message.error('Update supplier failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="supplier-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Supplier</h2>
            </div>
            <SupplierForm handleSubmit={handleSubmit} supplier={data} supplierId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SupplierUpdatePage;
