import React from 'react';

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const DownloadIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height={`${height || 24}px`}
      viewBox="0 0 24 24"
      width={`${width || 24}px`}
      fill={`${color || 'currentColor'}`}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
      </g>
    </svg>
  );
};
export default DownloadIcon;
