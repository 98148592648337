import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import { FilmI } from '../../../../interfaces/film';
import {
  capitalizeFirstLetterOfEachWord,
  handleGetCountriesWithSEA,
  mergeUniqueStringArray,
  replaceCountryName,
  setStartTime,
  setEndTime,
  convertDateTimeToTimestamp,
  isWorldWide,
  checkIsSEACountries,
  getCountriesList,
  getCountryLabel,
  getCountryValue,
} from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND, getSEACountries, SEACountries } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import { CustomerI } from '../../../../interfaces/customer';
import { useQuery } from 'react-query';
import { getCustomersList } from '../../../../api/customers';
import { doGetFilmsList } from '../../../../api/films';
import BackButton from '../../buttons/BackButton';
import DeleteForeverIcon from '../../../svgs/DeleteForeverIcon';
import { PlusOutlined } from '@ant-design/icons';
import { TerritoryI } from '../../../../interfaces/territory';
import { RightI } from '../../../../interfaces/right';
import './Form.scss';
import SecondaryButton from '../../buttons/SecondaryButton';
import FileCopyIcon from '../../../svgs/FilmCopyIcon';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const { RangePicker } = DatePicker;

type Props = {
  licenseId?: number;
  license?: any;
  handleSubmit(_: any): void;
};

type DBRights = {
  id: number;
  name: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  rightId: number;
};

type Rights = {
  id: number;
  name: string;
  startEndDate: [dayjs.Dayjs, dayjs.Dayjs][];
  rightId: number;
};

type TerritoriesRightForm = {
  id: string | null;
  remarks: string | null;
  rightId: number | null;
  startEndDateRanges: [dayjs.Dayjs, dayjs.Dayjs] | [];
};

interface TerritoriesForm extends TerritoryI {
  names: string[];
}

const LicenseForm: React.FC<Props> = ({ license, licenseId, handleSubmit }) => {
  const [form] = Form.useForm();
  const [selectedRegion, setSelectedRegion] = useState<{ [key: number]: string[] } | null>(null);
  const newCountryList = useMemo(() => getCountriesList(true), []);
  const countryWithWorldWide = [{ value: 'WORLDWIDE', label: 'WORLDWIDE' }].concat(newCountryList);
  const countryWithWorldWideLabel = countryWithWorldWide.map(({ label }) => label);
  const [countries, setCountries] = useState<{ value: string; label: string }[]>([]);
  const [territories, setTerritories] = useState<TerritoryI[]>([]);
  const { data: customers, isFetching: isCustomerFetching } = useQuery(['customers', 'list'], () =>
    getCustomersList({}),
  );
  const [overallLicenseDate, setOverallLicenseDate] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [territoriesRights, setTerritoriesRights] = useState<{
    [countryName: string]: {
      exclusiveRights: DBRights[];
      nonExclusiveRights: DBRights[];
    };
  } | null>(null);
  const [excludedTerritories, setExcludedTerritories] = useState<{ [key: number]: string[] } | null>(null);
  const [countryExclusiveRights, setCountryExclusiveRights] = useState<{
    [key: number]: { [rightId: number]: Omit<Rights, 'rightId'> };
  } | null>(null);
  const [countryNonExclusiveRights, setCountryNonExclusiveRights] = useState<{
    [key: number]: { [rightId: number]: Omit<Rights, 'rightId'> };
  } | null>(null);
  const [exclusiveSelectedRightID, setExclusiveSelectedRightID] = useState<{
    [key: number]: { [eKey: number]: number };
  } | null>(null);
  const [nonExclusiveSelectedRightID, setNonExclusiveSelectedRightID] = useState<{
    [key: number]: { [nKey: number]: number };
  } | null>(null);
  const [filmExcludedTerritories, setFilmExcludedTerritories] = useState<string[]>([]);

  const territoriesRightsRef = useRef(territoriesRights);
  const { data: films, isFetching: isFilmFetching } = useQuery(['films', 'list'], () => doGetFilmsList({}));

  const emptyRights: TerritoriesRightForm = {
    id: null,
    remarks: null,
    rightId: null,
    startEndDateRanges: [],
  };

  const handleOnFinish = (values: any) => {
    const newValues: any = values;
    const startDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToTimestamp(setStartTime(values.startEndDateRange[0]))
        : null;
    const endDate =
      values.startEndDateRange && Object.values(values.startEndDateRange).length > 0
        ? convertDateTimeToTimestamp(setEndTime(values.startEndDateRange[1]))
        : null;
    newValues.startDate = startDate;
    newValues.endDate = endDate;
    if (values?.territories && Object.keys(values.territories).length > 0) {
      const newTerritories = values.territories.map((territory: any, key: number) => {
        const exclusiveRights = territory.exclusiveRights || [];
        const nonExclusiveRights = territory.nonExclusiveRights || [];
        const newExclusiveRights = exclusiveRights.map((right: any) => {
          if (right.rightId) {
            const startDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setStartTime(right.startEndDateRange[0]))
                : null;
            const endDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setEndTime(right.startEndDateRange[1]))
                : null;
            return {
              rightId: right.rightId,
              startDate,
              endDate,
            };
          } else return undefined;
        });

        const newNonExclusiveRights = nonExclusiveRights.map((right: any) => {
          if (right.rightId) {
            const startDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setStartTime(right.startEndDateRange[0]))
                : null;
            const endDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setEndTime(right.startEndDateRange[1]))
                : null;
            return {
              rightId: right.rightId,
              startDate,
              endDate,
            };
          } else return undefined;
        });
        delete territory.id;
        let newNames: string[] = [];
        if (territory.names) {
          let newCountryCodes: string[] = [];
          if (territory.names.includes('WORLDWIDE')) {
            newNames = ['worldwide'];
          } else {
            if (territory.names.includes('SEA')) {
              const sea = getSEACountries;
              const territoriesWithoutSEA = territory.names.filter((item: string) => item !== 'SEA');
              newCountryCodes = mergeUniqueStringArray(sea, territoriesWithoutSEA);
            } else {
              newCountryCodes = territory.names;
            }
            newNames = newCountryCodes?.map((item: string) => {
              if (item === 'TL2' || item === 'east timor') {
                return 'East Timor';
              }
              if (item === 'sea') {
                return 'SEA';
              }
              if (!!getCountryLabel(countryWithWorldWide, item)) {
                return getCountryLabel(countryWithWorldWide, item);
              } else {
                return capitalizeFirstLetterOfEachWord(item);
              }
            });
          }
        }
        if (territory.name) delete territory.name;
        const excludedRegionNames = [
          ...new Set(
            (isWorldWide(key, selectedRegion) &&
              territory.excludedTerritories &&
              territory.excludedTerritories.flatMap((countryCode: string) =>
                countryCode.toLowerCase() === 'sea'
                  ? SEACountries.map((country) => replaceCountryName(country.toLowerCase()))
                  : getCountryLabel(countryWithWorldWide, countryCode).toLowerCase(),
              )) ||
              [],
          ),
        ];
        if (territory.excludedTerritories) delete territory.excludedTerritories;
        return {
          names: newNames.map((name) => replaceCountryName(name)),
          nonExclusiveRights: newNonExclusiveRights.filter((item: any) => item !== undefined),
          exclusiveRights: newExclusiveRights.filter((item: any) => item !== undefined),
          excludedTerritories: excludedRegionNames,
        };
      });
      newValues.territories = newTerritories;
      delete values.startEndDateRange;
    }
    handleSubmit(newValues);
  };

  function mergeTerritories(territories: any) {
    return territories.map((territory: any, key: number) => {
      const exclusiveRights = territory.exclusiveRights.map((right: RightI) => {
        const startDate = right.startDate;
        const endDate = right.endDate;
        return {
          rightId: right.rightId,
          startEndDateRange: !startDate && !endDate ? [] : [dayjs(right.startDate), dayjs(right.endDate)],
          remarks: right.remarks,
        };
      });

      const nonExclusiveRights = territory.nonExclusiveRights.map((right: RightI) => {
        const startDate = right.startDate;
        const endDate = right.endDate;
        return {
          rightId: right.rightId,
          startEndDateRange: !startDate && !endDate ? [] : [dayjs(right.startDate), dayjs(right.endDate)],
          remarks: right.remarks,
        };
      });
      let countryCodes: string[] | undefined = undefined;
      if (territory?.name) {
        if (territory.name.includes('worldwide')) {
          countryCodes = ['WORLDWIDE'];
          setSelectedRegion((state) => {
            if (state == null) state = {};
            return { ...state, [key]: ['WORLDWIDE'] };
          });
        } else {
          const newCountries = handleGetCountriesWithSEA(territory.name);
          countryCodes = newCountries.map((item: string) => getCountryValue(countryWithWorldWide, item) || item);
        }
      }
      return {
        ...territory,
        names: countryCodes,
        exclusiveRights,
        nonExclusiveRights,
      };
    });
  }

  const onFilmChange = (filmId: number) => {
    resetCountryExclusive();
    resetTerritoriesValue();
    if (filmId) {
      const film: FilmI = films?.data?.find((film: FilmI) => film.id === filmId);
      if (film == null) return;
      setOverallLicenseDate([dayjs(film.licenseStartDate), dayjs(film.licenseEndDate)]);
      formatTerritoriesRights(film.territories);
      form.setFieldValue('startEndDateRange', []);

      if (film && licenseId) {
        const territories: any[] = film.territories;
        const mergedTerritories: any[] = mergeTerritories(territories);
        setTerritories(territories);

        form.setFieldsValue({
          territories: mergedTerritories,
        });
      }
    } else {
      setTerritories([]);
      form.setFieldsValue({
        territories: [
          {
            exclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
            nonExclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
          },
        ],
        startEndDateRange: [],
      });
    }
  };

  function resetCountryExclusive() {
    setExclusiveSelectedRightID(null);
    setNonExclusiveSelectedRightID(null);
    setCountryExclusiveRights(null);
    setCountryNonExclusiveRights(null);
    setCountries([]);
    setTerritoriesRights(null);
  }
  function resetTerritoriesValue() {
    setSelectedRegion(null);
    const territories:
      | {
          exclusiveRights: TerritoriesRightForm[];
          names: string[];
          nonExclusiveRights: TerritoriesRightForm[];
        }[]
      | null = form.getFieldValue('territories') || null;
    if (territories == null) return;

    const emptyTerritories = territories.map(({ exclusiveRights = [], nonExclusiveRights = [] }) => ({
      exclusiveRights: exclusiveRights.map(() => emptyRights),
      names: [],
      nonExclusiveRights: nonExclusiveRights.map(() => emptyRights),
    }));
    form.setFieldValue('territories', emptyTerritories);
  }

  function formatTerritoriesRights(territories: { [key: string]: any }[] = []) {
    const countriesArr: { label: string; value: string }[] = [];
    // seaAndWorldWideArr is for SEA and WORLDWIDE only, this is done to make it the first 2 value of the list
    const seaAndWorldWideArr: { label: string; value: string }[] = [];
    territories.forEach((territory) => {
      const excludedTerritories: string[] =
        (territory?.excludedTerritories && handleGetCountriesWithSEA(territory?.excludedTerritories)) || [];
      if (territory?.isWorldwide) {
        territory.name = countryWithWorldWideLabel.filter(
          (country) =>
            !excludedTerritories.some((excludedCountry) => {
              if (excludedCountry.toLowerCase() === 'sea')
                return (
                  SEACountries.some((seaCountry) => seaCountry.toLowerCase() === country.toLowerCase()) ||
                  country.toLowerCase() === 'sea'
                );

              return excludedCountry.toLowerCase() === country.toLowerCase();
            }),
        );
        const excludedTerritoriesCode: string[] =
          excludedTerritories.map((country: string) =>
            country.toLowerCase() === 'sea' ? 'SEA' : getCountryValue(countryWithWorldWide, country),
          ) || [];
        setFilmExcludedTerritories((state) => [...new Set(state.concat(excludedTerritoriesCode))]);
      }
      if (territory?.isWorldwide) {
        // if is worldwide, push to territory.name and unshift seaAndWorldWideArr to make worldwide first vallue
        seaAndWorldWideArr.unshift({ label: 'WORLDWIDE', value: 'WORLDWIDE' });
        // push to territory.name so it will create a key for worldwide
        territory.name.push('WORLDWIDE');
        if (excludedTerritories.includes('SEA') === false) {
          // if SEA isn't excluded, add it to seaAndWorldWideArr and territory.name
          seaAndWorldWideArr.push({ label: 'SEA', value: 'SEA' });
          territory.name.push('SEA');
        }
      }
      const haveSEACountries = (territory.name && checkIsSEACountries(territory.name)) || false;
      if (territory.name && haveSEACountries) {
        seaAndWorldWideArr.push({ label: 'SEA', value: 'SEA' });
        // push to territory.name so it will create  a key for SEA
        territory.name.push('SEA');
      }
      const uniqueCountriesObj = territory.name && getCountriesOptionWithCountries(territory.name);
      if (Array.isArray(uniqueCountriesObj) === false) return;
      uniqueCountriesObj.forEach(({ value: countryValue, label }: { value: string; label: string }) => {
        countriesArr.push({ label, value: countryValue });
        setTerritoriesRights((state) => {
          const exclusiveRights: DBRights[] = territory.exclusiveRights.map(
            ({ endDate, id, name, rightId, startDate }: DBRights) => ({
              endDate: setEndTime(dayjs(endDate)),
              id,
              name,
              rightId,
              startDate: setStartTime(dayjs(startDate)),
            }),
          );
          const nonExclusiveRights: DBRights[] = territory.nonExclusiveRights.map(
            ({ endDate, id, name, rightId, startDate }: DBRights) => ({
              endDate: setEndTime(dayjs(endDate)),
              id,
              name,
              rightId,
              startDate: setStartTime(dayjs(startDate)),
            }),
          );

          if (state == null) state = {};
          return {
            ...state,
            [countryValue]: {
              ...state[countryValue],
              exclusiveRights:
                (state[countryValue]?.exclusiveRights && state[countryValue].exclusiveRights.concat(exclusiveRights)) ||
                exclusiveRights,
              nonExclusiveRights:
                (state[countryValue]?.nonExclusiveRights &&
                  state[countryValue].nonExclusiveRights.concat(nonExclusiveRights)) ||
                nonExclusiveRights,
            },
          };
        });
      });
    });
    const removeDuplicateCountries = Object.values(
      countriesArr.reduce((accumulator: { [key: string]: { label: string; value: string } }, currentValue) => {
        // remove SEA and WORLDWIDE from this array since seaAndWorldWideArr have those 2 values
        if (currentValue.value === 'SEA' || currentValue.value === 'WORLDWIDE') return accumulator;
        accumulator[currentValue.value] = currentValue;
        return accumulator;
      }, {}),
    ).sort((a, b) => a.label.localeCompare(b.label));
    const removeDuplicateSEAAndWorldwide = Object.values(
      seaAndWorldWideArr.reduce((accumulator: { [key: string]: { label: string; value: string } }, currentValue) => {
        accumulator[currentValue.value] = currentValue;
        return accumulator;
      }, {}),
    );
    setCountries(removeDuplicateSEAAndWorldwide.concat(removeDuplicateCountries));
  }

  function getCountriesOptionWithCountries(countries: string[]): { label: string; value: string }[] {
    return countryWithWorldWide.filter((country) =>
      countries.some((unique) => unique.toLowerCase() === country.label.toLowerCase()),
    );
  }
  function handleCountryChange(selectedValues: string[], key: number) {
    setSelectedRegion((state) => {
      if (state == null) state = {};
      return {
        ...state,
        [key]: selectedValues,
      };
    });
    if (selectedValues.length === 0) {
      checkAndResetSelectedRights([], key, 'EXCLUSIVE');
      checkAndResetSelectedRights([], key, 'NON_EXCLUSIVE');
      setExcludedTerritories((state) => {
        if (state == null) state = {};
        return { ...state, [key]: [] };
      });
      return;
    }
    if (selectedValues.includes('WORLDWIDE')) {
      const excluded =
        excludedTerritories && excludedTerritories[key]?.length > 0
          ? excludedTerritories && excludedTerritories[key]
          : filmExcludedTerritories;
      const territories: { exclusiveRights: Rights[]; names: string[]; nonExclusivesRights: Rights[] }[] =
        form.getFieldValue('territories') || [];
      form.setFieldValue(
        'territories',
        territories.map((territory, index) =>
          index === key ? { ...territory, excludedTerritories: excluded } : territory,
        ),
      );
    }
    // check if is only 1 countries
    if (selectedValues.length === 1) {
      const selectedCountry = selectedValues[0];
      if (territoriesRights == null) return;
      const exclusiveRights = territoriesRights?.[selectedCountry]?.exclusiveRights.reduce(
        (accumulator: { [rightId: number]: Omit<Rights, 'rightId'> }, currentValue) => {
          const { rightId, startDate, endDate, ...rest } = currentValue;
          const startEndDate = (accumulator[rightId] && accumulator[rightId]?.startEndDate) || [];
          accumulator[rightId] = {
            ...rest,
            startEndDate: startEndDate.concat([[startDate, endDate]]),
          };
          return accumulator;
        },
        {},
      );
      if (exclusiveRights) {
        const exclusiveRightsId = Object.keys(exclusiveRights)?.map((rightId) => parseInt(rightId));
        checkAndResetSelectedRights(exclusiveRightsId, key, 'EXCLUSIVE');
        setCountryExclusiveRights((state) => {
          if (state == null) state = {};
          return {
            ...state,
            [key]: exclusiveRights,
          };
        });
      }

      const nonExclusiveRights = territoriesRights?.[selectedCountry]?.nonExclusiveRights
        .concat(territoriesRights?.[selectedCountry]?.exclusiveRights)
        .reduce((accumulator: { [rightId: number]: Omit<Rights, 'rightId'> }, currentValue) => {
          const { rightId, startDate, endDate, ...rest } = currentValue;
          const startEndDate = (accumulator[rightId] && accumulator[rightId]?.startEndDate) || [];
          accumulator[rightId] = {
            ...rest,
            startEndDate: startEndDate.concat([[startDate, endDate]]),
          };
          return accumulator;
        }, {});

      if (nonExclusiveRights) {
        const nonExclusiveRightsId = Object.keys(nonExclusiveRights).map((rightId) => parseInt(rightId));
        checkAndResetSelectedRights(nonExclusiveRightsId, key, 'NON_EXCLUSIVE');
        setCountryNonExclusiveRights((state) => {
          if (state == null) state = {};
          return {
            ...state,
            [key]: nonExclusiveRights,
          };
        });
      }
    } else {
      if (territoriesRights == null) return;
      const allExclusiveRights = selectedValues.flatMap(
        (country) => territoriesRights[country] && territoriesRights[country].exclusiveRights,
      );
      if (allExclusiveRights.some((rights) => rights == null)) return;
      const commonExclusiveRights = getAllCommonRights(allExclusiveRights, selectedValues.length);
      const commonExclusiveRightsId = Object.keys(commonExclusiveRights).map((id) => parseInt(id));
      checkAndResetSelectedRights(commonExclusiveRightsId, key, 'EXCLUSIVE');
      const allNonExclusiveRights = selectedValues.flatMap(
        (country) => territoriesRights[country] && territoriesRights[country].nonExclusiveRights,
      );
      let commonNonExclusiveRights = getAllCommonRights(allNonExclusiveRights, selectedValues.length);
      const commonNonExclusiveRightsId = Object.keys(commonNonExclusiveRights).map((id) => parseInt(id));
      checkAndResetSelectedRights(commonNonExclusiveRightsId, key, 'NON_EXCLUSIVE');
      setCountryExclusiveRights((state) => {
        if (state == null) state = {};
        return { ...state, [key]: commonExclusiveRights };
      });

      Object.keys(commonExclusiveRights).forEach((key) => {
        const intKey = parseInt(key);
        if (commonNonExclusiveRights[intKey] != null)
          commonNonExclusiveRights[intKey].startEndDate = commonNonExclusiveRights[intKey].startEndDate.concat(
            commonExclusiveRights[intKey].startEndDate,
          );
        else commonNonExclusiveRights = { ...commonNonExclusiveRights, [intKey]: commonExclusiveRights[intKey] };
      });

      setCountryNonExclusiveRights((state) => {
        if (state == null) state = {};
        return {
          ...state,
          [key]: commonNonExclusiveRights,
        };
      });
    }
  }

  function getAllCommonRights(
    exclusives: DBRights[],
    numOfCountry: number,
  ): { [rightId: number]: Omit<Rights, 'rightId'> } {
    const exclusiveCounter = exclusives.reduce(
      (
        accumulator: {
          [id: number]: Omit<Rights, 'startEndDate'> & {
            startEndDate: { date: [dayjs.Dayjs, dayjs.Dayjs]; count: number }[];
          };
        },
        currentValue,
      ) => {
        const { rightId, startDate, endDate, ...rest } = currentValue;
        if (accumulator[currentValue.rightId] == null)
          accumulator[rightId] = { ...rest, startEndDate: [{ date: [startDate, endDate], count: 1 }], rightId };
        else {
          const indexOfDate = accumulator[rightId].startEndDate.findIndex((startEndDate) => {
            return (
              startDate.isBetween(startEndDate.date[0], startEndDate.date[1], 'day', '[]') ||
              endDate.isBetween(startEndDate.date[0], startEndDate.date[1], 'day', '[]')
            );
          });

          if (indexOfDate === -1) {
            accumulator[rightId].startEndDate.push({ date: [startDate, endDate], count: 1 });
          } else {
            const startDate = accumulator[rightId].startEndDate[indexOfDate].date[0].isSameOrAfter(
              currentValue.startDate,
            )
              ? accumulator[rightId].startEndDate[indexOfDate].date[0]
              : currentValue.startDate;
            const endDate = accumulator[rightId].startEndDate[indexOfDate].date[1].isSameOrBefore(currentValue.endDate)
              ? accumulator[rightId].startEndDate[indexOfDate].date[1]
              : currentValue.endDate;

            accumulator[rightId].startEndDate[indexOfDate] = {
              date: [startDate, endDate],
              count: accumulator[rightId].startEndDate[indexOfDate].count + 1,
            };
          }
        }

        return accumulator;
      },
      {},
    );
    const commonExclusiveRightsObj = Object.keys(exclusiveCounter).reduce(
      (accumulator: { [key: number]: Rights }, currentValue) => {
        const currentRightId = parseInt(currentValue);
        const startDateArr = exclusiveCounter[currentRightId].startEndDate.reduce(
          (accumulator: [dayjs.Dayjs, dayjs.Dayjs][], currenValue) => {
            if (currenValue.count >= numOfCountry) accumulator.push(currenValue.date);
            return accumulator;
          },
          [],
        );
        if (startDateArr.length > 0)
          accumulator[parseInt(currentValue)] = { ...exclusiveCounter[currentRightId], startEndDate: startDateArr };
        return accumulator;
      },
      {},
    );
    return Object.values(commonExclusiveRightsObj).reduce(
      (accumulator: { [rightId: number]: Omit<Rights, 'rightId'> }, currentValue) => {
        const { rightId, ...rest } = currentValue;
        accumulator[rightId] = rest;
        return accumulator;
      },
      {},
    );
  }

  function checkAndResetSelectedRights(allRightsId: number[], key: number, rightsType: 'EXCLUSIVE' | 'NON_EXCLUSIVE') {
    let territories:
      | {
          exclusiveRights: TerritoriesRightForm[];
          names: string[];
          nonExclusiveRights: TerritoriesRightForm[];
        }[]
      | {
          exclusiveRights: TerritoriesRightForm[];
          names: string[];
          nonExclusiveRights: TerritoriesRightForm[];
        }
      | null = form.getFieldValue('territories') || null;
    if (territories == null) return;
    function checkAndReset(rights: TerritoriesRightForm[]): TerritoriesRightForm[] {
      return rights.reduce((accumulator: TerritoriesRightForm[], currentValue) => {
        const rights = currentValue.rightId && allRightsId.includes(currentValue.rightId) ? currentValue : emptyRights;

        accumulator.push(rights);
        return accumulator;
      }, []);
    }

    if (Array.isArray(territories) && territories != null) {
      const resettedTerritories = territories.map(
        ({ exclusiveRights = [], nonExclusiveRights = [], ...rest }, index) => {
          if (index !== key) return { ...rest, exclusiveRights, nonExclusiveRights };
          if (rightsType === 'EXCLUSIVE') {
            return {
              ...rest,
              exclusiveRights: checkAndReset(exclusiveRights),
              nonExclusiveRights,
            };
          } else {
            return {
              ...rest,
              exclusiveRights,
              nonExclusiveRights: checkAndReset(nonExclusiveRights),
            };
          }
        },
      );
      form.setFieldValue('territories', resettedTerritories);
    } else {
      // if is just 1 territory, it will be object(dict)
      const { exclusiveRights, nonExclusiveRights, ...rest } = territories;
      const resettedTerritories =
        rightsType === 'EXCLUSIVE'
          ? { ...rest, exclusiveRights: checkAndReset(exclusiveRights), nonExclusiveRights }
          : { ...rest, exclusiveRights, nonExclusiveRights: checkAndReset(nonExclusiveRights) };
      form.setFieldValue('territories', [resettedTerritories]);
    }
  }

  function handleExclusiveRight(key: number, eKey: number, selectedValues: number) {
    setExclusiveSelectedRightID((state) => {
      if (state == null) state = {};
      return { ...state, [key]: { ...state[key], [eKey]: selectedValues } };
    });
  }

  function handleNonExclusiveRight(key: number, nKey: number, selectedValues: number) {
    setNonExclusiveSelectedRightID((state) => {
      if (state == null) state = {};
      return { ...state, [key]: { ...state[key], [nKey]: selectedValues } };
    });
  }

  const onDuplicateClick = () => {
    const territories = form.getFieldsValue().territories;
    const duplicateData = [...territories, ...territories];
    setSelectedRegion((state) => {
      // nothing to duplicate
      if (state == null) return state;
      const duplicatedSelectedRights = Object.keys(state).reduce(
        (
          accumulator: {
            [key: number]: string[];
          },
          currentValue,
          index,
        ) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(currentValue)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedSelectedRights };
    });
    duplicateExclusives();
    duplicateSelectedRights();
    form.setFieldsValue({ territories: duplicateData });
  };

  function duplicateExclusives() {
    setCountryExclusiveRights((state) => {
      // if empty nothing to duplicate
      if (state == null) return state;
      const duplicatedExclusiveRights = Object.keys(state).reduce(
        (accumulator: { [key: number]: { [rightId: number]: Omit<Rights, 'rightId'> } }, key, index) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(key)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedExclusiveRights };
    });

    setCountryNonExclusiveRights((state) => {
      // if empty nothing to duplicate
      if (state == null) return null;
      const duplicatedNonExclusiveRights = Object.keys(state).reduce(
        (accumulator: { [key: number]: { [rightId: number]: Omit<Rights, 'rightId'> } }, key, index) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(key)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedNonExclusiveRights };
    });
  }

  function duplicateSelectedRights() {
    setExclusiveSelectedRightID((state) => {
      // if empty nothing to duplicate
      if (state == null) return state;
      const duplicatedExclusiveSelectedRightID = Object.keys(state).reduce(
        (accumulator: { [key: number]: { [eKey: number]: number } }, key, index) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(key)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedExclusiveSelectedRightID };
    });

    setNonExclusiveSelectedRightID((state) => {
      if (state == null) return state;
      const duplicatedNonExclusiveSelectedRightID = Object.keys(state).reduce(
        (accumulator: { [key: number]: { [eKey: number]: number } }, key, index) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(key)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedNonExclusiveSelectedRightID };
    });
  }

  function disabledDate(current: dayjs.Dayjs, dateRange: [dayjs.Dayjs, dayjs.Dayjs] | null) {
    if (dateRange == null) return true;
    const [startDate, endDate] = dateRange;
    return current && (current.isBefore(setStartTime(startDate)) || current.isAfter(setEndTime(endDate)));
  }

  function disableTwoDate(
    current: dayjs.Dayjs,
    overallStartDate: dayjs.Dayjs | null,
    overallEndDate: dayjs.Dayjs | null,
    startEndDate: [dayjs.Dayjs, dayjs.Dayjs][],
  ) {
    if (overallStartDate == null || overallEndDate == null || startEndDate.length === 0) return true;
    // case 1: licenseStartDate and licenseEndDate is within overallStartDate and overallEndDate
    // case 2: overallStartDate is after licenseStartDate but licenseEndDate is before overallEndDate
    // case 3: overallStartDate is after licenseStartDate but overallEndDate is before licenseEndDate
    // case 4: overallStartDate is before licenseStartDate but overallEndDate is before licenseEndDate
    const licenseWithinOverallRange = startEndDate.some((startEndDate) => {
      const [licenseStartDate, licenseEndDate] = startEndDate;
      return (
        (licenseStartDate.isSameOrAfter(overallStartDate, 'day') &&
          licenseEndDate.isSameOrBefore(overallEndDate, 'day') &&
          current.isSameOrAfter(licenseStartDate, 'day') &&
          current.isSameOrBefore(licenseEndDate, 'day')) ||
        (overallStartDate.isSameOrAfter(licenseStartDate, 'day') &&
          licenseEndDate.isSameOrBefore(overallEndDate, 'day') &&
          current.isSameOrAfter(overallStartDate, 'day') &&
          current.isSameOrBefore(licenseEndDate, 'day')) ||
        (overallStartDate.isSameOrAfter(licenseStartDate, 'day') &&
          overallEndDate.isSameOrBefore(licenseEndDate, 'day') &&
          current.isSameOrAfter(overallStartDate, 'day') &&
          current.isSameOrBefore(overallEndDate, 'day')) ||
        (licenseStartDate.isSameOrAfter(overallStartDate, 'day') &&
          licenseEndDate.isSameOrAfter(overallEndDate, 'day') &&
          current.isSameOrAfter(licenseStartDate, 'day') &&
          current.isSameOrBefore(overallEndDate, 'day'))
      );
    });

    return !licenseWithinOverallRange;
  }

  function overallStartEndDateReset(dates: any) {
    if (films && license && license.id === licenseId && dates == null) {
      const film: FilmI = films?.data?.find((film: FilmI) => film.id === license.filmId);
      setOverallLicenseDate([dayjs(film?.licenseStartDate), dayjs(film?.licenseEndDate)]);
    }
  }
  useEffect(() => {
    if (license && license.id === licenseId) {
      setOverallLicenseDate([license?.startDate, license?.endDate]);
      const territories: any[] = license?.licenseTerritories;
      const mergedTerritories: any[] = mergeTerritories(territories);
      setTerritories(territories);

      mergedTerritories.forEach((territory, key) => {
        const selectedExclusiveRights = territory?.exclusiveRights.reduce(
          (
            accumulator: { [key: number]: { [eKey: number]: number } },
            { rightId }: { rightId: number; startEndDateRange: dayjs.Dayjs[]; remarks: string | null },
            eKey: number,
          ) => {
            if (accumulator[key] == null) accumulator[key] = {};
            accumulator[key][eKey] = rightId;
            return accumulator;
          },
          {},
        );
        const selectedNonExclusiveRights = territory?.nonExclusiveRights.reduce(
          (
            accumulator: { [key: number]: { [eKey: number]: number } },
            { rightId }: { rightId: number; startEndDateRange: dayjs.Dayjs[]; remarks: string | null },
            nKey: number,
          ) => {
            if (accumulator[key] == null) accumulator[key] = {};
            accumulator[key][nKey] = rightId;
            return accumulator;
          },
          {},
        );
        setExclusiveSelectedRightID((state) => ({ ...state, ...selectedExclusiveRights }));
        setNonExclusiveSelectedRightID((state) => ({ ...state, ...selectedNonExclusiveRights }));
      });
      form.setFieldsValue({
        ...license,
        startEndDateRange:
          license?.startDate && license?.endDate ? [dayjs(license.startDate), dayjs(license.endDate)] : null,
        territories: mergedTerritories,
      });
    }
    if (films && license) {
      const film: FilmI = films?.data?.find((film: FilmI) => film.id === license.filmId);
      formatTerritoriesRights(film?.territories);
    }
  }, [license, films]);
  useEffect(() => {
    if (license && territoriesRights !== territoriesRightsRef.current && territoriesRights != null) {
      const selectedTerritories: TerritoriesForm[] = [];
      const territoriesValue = form.getFieldValue('territories');
      territories.forEach((territory, index) => {
        const countriesWithSEA = (territory.name && handleGetCountriesWithSEA(territory.name)) || [];
        const selectedCountries = getCountriesOptionWithCountries(countriesWithSEA);
        const selectedCountriesValue = selectedCountries.map(({ value }) => value);
        territory.name && handleCountryChange(selectedCountriesValue, index);
        const existingTerritory = territoriesValue[index] || {};
        const { excludedTerritories } = territory;
        const excludedCountriesWithSEA = (excludedTerritories && handleGetCountriesWithSEA(excludedTerritories)) || [];
        const selectedExcludedCountries = getCountriesOptionWithCountries(
          excludedCountriesWithSEA.map((country) => country.trim()),
        );
        const selectedExcludedCountriesValue = selectedExcludedCountries.map(({ value }) => value.trim());
        setExcludedTerritories((state) => ({ ...state, [index]: selectedExcludedCountriesValue }));
        selectedTerritories.push({
          ...existingTerritory,
          names: selectedCountriesValue,
          excludedTerritories: selectedExcludedCountriesValue,
        });
      });
      form.setFieldValue('territories', selectedTerritories);
      territoriesRightsRef.current = territoriesRights;
    }
  }, [territoriesRights, territoriesRightsRef.current, license]);

  return (
    <div className="license-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{
          territories: [
            {
              exclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
              nonExclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
            },
          ],
        }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Customer" name="customerId" rules={[{ required: true, message: 'required' }]}>
              <Select
                placeholder="Required"
                showSearch
                optionFilterProp="children"
                loading={isCustomerFetching}
                allowClear
              >
                {customers?.data?.map((item: CustomerI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Film" name="filmId" rules={[{ required: true, message: 'required' }]}>
              <Select
                placeholder="Required"
                showSearch
                optionFilterProp="children"
                loading={isFilmFetching}
                onChange={onFilmChange}
                allowClear
                disabled={licenseId != null}
              >
                {films?.data?.map((item: FilmI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="startEndDateRange"
              label="Start - End Date"
              rules={[{ required: true, message: 'required' }]}
            >
              <RangePicker
                className="ant"
                format={DATE_FORMAT_FRONTEND}
                onChange={(dates) => overallStartEndDateReset(dates)}
                disabledDate={(current) => disabledDate(current, overallLicenseDate)}
              />
            </Form.Item>
            <Form.Item
              name="contractReferenceNumber"
              label="Contract Reference Number"
              rules={[{ required: true, message: 'required' }]}
            >
              <Input.TextArea rows={1} placeholder="Required" />
            </Form.Item>
            <Form.Item name="remark" label="Remark">
              <Input.TextArea rows={3} placeholder="Optional" />
            </Form.Item>
            {territories && Object.keys(territories).length >= 0 && (
              <Fragment>
                <div className="page-content-header">
                  <h2 className="title-page">Add Territories</h2>
                  <div>
                    <SecondaryButton label="Duplicate" onClick={onDuplicateClick} icon={<FileCopyIcon />} />
                  </div>
                </div>

                <Form.List name="territories">
                  {(fields, { add, remove }) => {
                    return (
                      <div className="territory-list-section">
                        {fields.map(({ key, name, ...restField }) => (
                          <Fragment key={key}>
                            <div>
                              <Form.Item {...restField} label="Region" name={[name, 'names']}>
                                <Select
                                  placeholder="Required"
                                  showSearch
                                  optionFilterProp="label"
                                  options={countries}
                                  mode="multiple"
                                  onChange={(selectedValue: string[]) => handleCountryChange(selectedValue, key)}
                                />
                              </Form.Item>

                              {isWorldWide(key, selectedRegion) && (
                                <Form.Item {...restField} label="Excluded Region" name={[name, 'excludedTerritories']}>
                                  <Select
                                    placeholder="Optional"
                                    showSearch
                                    optionFilterProp="label"
                                    options={newCountryList}
                                    mode="multiple"
                                    allowClear
                                  />
                                </Form.Item>
                              )}
                              <div className="rights-list-section">
                                <h2 className="subtitle-page">Exclusive Rights</h2>
                                <Form.List name={[name, 'exclusiveRights']}>
                                  {(exclusiveRights, { add, remove }) => {
                                    return (
                                      <div className="right-item-section">
                                        {exclusiveRights.map(({ key: eKey, name: eName, ...eRestField }) => (
                                          <Fragment key={eKey}>
                                            <div>
                                              <Form.Item {...eRestField} label="Right" name={[eName, 'rightId']}>
                                                <Select
                                                  placeholder="Required"
                                                  showSearch
                                                  optionFilterProp="children"
                                                  onChange={(selectedValue) =>
                                                    handleExclusiveRight(key, eKey, selectedValue)
                                                  }
                                                >
                                                  {countryExclusiveRights &&
                                                    countryExclusiveRights[key] &&
                                                    Object.keys(countryExclusiveRights[key]).map((rightId: string) => {
                                                      const exclusiveRights =
                                                        countryExclusiveRights[key][parseInt(rightId)];
                                                      return (
                                                        <Select.Option
                                                          key={parseInt(rightId)}
                                                          value={parseInt(rightId)}
                                                        >
                                                          {exclusiveRights && exclusiveRights.name}
                                                        </Select.Option>
                                                      );
                                                    })}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                {...eRestField}
                                                label="Start - End Date"
                                                name={[eName, 'startEndDateRange']}
                                              >
                                                <RangePicker
                                                  className="ant"
                                                  format={DATE_FORMAT_FRONTEND}
                                                  disabledDate={(current) => {
                                                    const startEndDate =
                                                      (countryExclusiveRights &&
                                                        exclusiveSelectedRightID &&
                                                        countryExclusiveRights[key] &&
                                                        countryExclusiveRights[key][
                                                          exclusiveSelectedRightID[key]?.[eKey]
                                                        ]?.startEndDate) ||
                                                      [];

                                                    const [overallStartDate, overallEndDate] =
                                                      form.getFieldValue('startEndDateRange') || [];

                                                    return disableTwoDate(
                                                      current,
                                                      overallStartDate,
                                                      overallEndDate,
                                                      startEndDate,
                                                    );
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                            {exclusiveRights.length >= 1 && (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  remove(eName);
                                                }}
                                                block
                                                className="btn-remove"
                                              >
                                                <DeleteForeverIcon width={16} height={16} /> Remove Exclusive Right
                                              </Button>
                                            )}
                                          </Fragment>
                                        ))}

                                        <Form.Item>
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              add({ startEndDateRange: [], id: '', remarks: '' });
                                            }}
                                            block
                                          >
                                            <PlusOutlined /> Add Exclusive Right
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    );
                                  }}
                                </Form.List>
                                <h2 className="subtitle-page">Non Exclusive Rights</h2>
                                <Form.List name={[name, 'nonExclusiveRights']}>
                                  {(nonExclusiveRights, { add, remove }) => {
                                    return (
                                      <div className="right-item-section">
                                        {nonExclusiveRights.map(({ key: nKey, name: nName, ...nRestField }) => (
                                          <Fragment key={nKey}>
                                            <div>
                                              <Form.Item {...nRestField} label="Right" name={[nName, 'rightId']}>
                                                <Select
                                                  placeholder="Required"
                                                  showSearch
                                                  optionFilterProp="children"
                                                  onChange={(selected) => handleNonExclusiveRight(key, nKey, selected)}
                                                >
                                                  {countryNonExclusiveRights &&
                                                    countryNonExclusiveRights[key] &&
                                                    Object.keys(countryNonExclusiveRights[key]).map(
                                                      (rightId: string) => {
                                                        return (
                                                          <Select.Option
                                                            key={parseInt(rightId)}
                                                            value={parseInt(rightId)}
                                                          >
                                                            {countryNonExclusiveRights[key][parseInt(rightId)].name}
                                                          </Select.Option>
                                                        );
                                                      },
                                                    )}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                {...nRestField}
                                                label="Start - End Date"
                                                name={[nName, 'startEndDateRange']}
                                              >
                                                <RangePicker
                                                  className="ant"
                                                  format={DATE_FORMAT_FRONTEND}
                                                  disabledDate={(current) => {
                                                    const nonExclusiveStartEndDate =
                                                      (countryNonExclusiveRights &&
                                                        nonExclusiveSelectedRightID &&
                                                        countryNonExclusiveRights[key] &&
                                                        countryNonExclusiveRights[key][
                                                          nonExclusiveSelectedRightID[key]?.[nKey]
                                                        ]?.startEndDate) ||
                                                      [];

                                                    const [overallStartDate, overallEndDate] =
                                                      form.getFieldValue('startEndDateRange') || [];

                                                    return disableTwoDate(
                                                      current,
                                                      overallStartDate,
                                                      overallEndDate,
                                                      nonExclusiveStartEndDate,
                                                    );
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                            {nonExclusiveRights.length >= 1 && (
                                              <Button
                                                type="dashed"
                                                onClick={() => {
                                                  remove(nName);
                                                }}
                                                block
                                                className="btn-remove"
                                              >
                                                <DeleteForeverIcon width={16} height={16} /> Remove Non Exclusive Right
                                              </Button>
                                            )}
                                          </Fragment>
                                        ))}
                                        <Form.Item>
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              add({ startEndDateRange: [], id: '', remarks: '' });
                                            }}
                                            block
                                          >
                                            <PlusOutlined /> Add Non Exclusive Right
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    );
                                  }}
                                </Form.List>
                              </div>
                            </div>
                            {fields.length >= 1 && (
                              <Button
                                type="dashed"
                                onClick={() => {
                                  remove(name);
                                }}
                                block
                                className="btn-remove"
                              >
                                <DeleteForeverIcon width={16} height={16} /> Remove Territory
                              </Button>
                            )}
                          </Fragment>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add({
                                id: '',
                                starstartEndDateRangetEnd: '',
                              });
                            }}
                            block
                          >
                            <PlusOutlined /> Add Territory
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Fragment>
            )}
          </div>
          <div className="form-footer">
            <PrimaryButton label={licenseId ? 'Update' : 'Create'} icon={<CheckCircleIcon />} htmlType="submit" />
            <BackButton label={'Cancel'} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LicenseForm;
