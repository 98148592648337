import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { doCreateSupplier } from '../../api/suppliers';
import { SupplierI } from '../../interfaces/supplier';
import SupplierForm from '../../shared/components/forms/supplier/Form';

const SupplierNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(doCreateSupplier, {
    onSuccess: async () => {
      message.info('Create supplier successfully!');
      queryClient.invalidateQueries({
        queryKey: ['suppliers', 'list'],
      });
      navigate(`/suppliers`, { replace: true });
    },
    onError: () => {
      message.error('Create supplier failed! Please try again');
    },
  });

  const handleSubmit = async (values: SupplierI) => {
    createMutate(values);
  };

  return (
    <div className="supplier-new-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Supplier</h2>
          </div>
          <SupplierForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default SupplierNewPage;
