import React from 'react';
import { doResetPassword } from '../../api/auth';
import { useMutation } from 'react-query';
import { message } from 'antd';
import ResetPasswordForm from '../../shared/components/forms/ResetPassword';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordParamsI } from '../../interfaces/auth';

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token: string | undefined = searchParams ? searchParams.get('token') || undefined : undefined;

  const navigate = useNavigate();

  const { mutate: createMutate } = useMutation(doResetPassword, {
    onSuccess: async () => {
      message.info('Your password has updated. Try to login again with new password.');
      navigate(`/login`);
    },
  });

  const handleSubmit = async (values: ResetPasswordParamsI) => {
    const newValues: ResetPasswordParamsI = values;
    newValues.token = token;
    createMutate(newValues);
  };

  return (
    <div className="login-container">
      <div className="content-page">
        <ResetPasswordForm handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
