import { Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteUser, getUsersList, updateUser } from '../../api/users';
import { QueryParams } from '../../interfaces';
import { User } from '../../interfaces/user';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import UsersFilterForm from '../../shared/components/forms/user/Filter';
import UsersTable from '../../shared/components/tables/Users';
import { PAGE_SIZE } from '../../shared/constants';
import AddCircleOutlineIcon from '../../shared/svgs/AddCircleOutlineIcon';

const UsersPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });

  const { data, isLoading } = useQuery(['users', 'list', queryParams], () => getUsersList(queryParams));

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  const { mutateAsync: mutationDelete } = useMutation(deleteUser, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
    },
  });

  const handleClickDelete = (user: User) => {
    mutationDelete(user.id);
  };

  const handleFilterSubmit = (values: any) => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: values });
  };

  const handleResetFilter = () => {
    setQueryParams({ start: 0, count: PAGE_SIZE, filter: undefined });
  };

  const { mutateAsync: updateMutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      message.info('Unblocked this user.');
    },
    onError: () => {
      message.error('Update user failed! Please try again');
    },
  });

  const handleUnblockUser = async (values: any) => {
    const newValues: any = values;
    const id = values.id;
    delete values.blockedAt;
    newValues.blockedAt = 'null';
    updateMutate({ ...newValues, id });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Link to={`/users/${record?.id}`}>
                    <Button className="btn-table-action-primary" type="link">
                      View
                    </Button>
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                  <Link to={`/users/${record?.id}/edit`}>
                    <Button className="btn-table-action-primary" type="link">
                      Edit
                    </Button>
                  </Link>
                ),
              },
              record.blockedAt !== null
                ? {
                    key: '3',
                    label: (
                      <Menu.Item key="unblock">
                        <Button
                          className="btn-table-action-primary"
                          type="link"
                          onClick={() => handleUnblockUser(record)}
                        >
                          Unblock User
                        </Button>
                      </Menu.Item>
                    ),
                  }
                : null,
              {
                key: '4',
                label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="users-container">
      <div className="page-container">
        <div className="page-header">
          <div></div>
          <div className="pagee-header-actions">
            <Link to="/users/new">
              <PrimaryButton label="Create User" icon={<AddCircleOutlineIcon />} />
            </Link>
          </div>
        </div>
        <div className="page-content">
          <UsersFilterForm handleFilter={handleFilterSubmit} handleReset={handleResetFilter} />
          <UsersTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={data?.data}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
