import { message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateFilm, useGetFilmDetails } from '../../api/films';
import FilmForm from '../../shared/components/forms/film/Form';

const FilmUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading, refetch: refetchFilmDetail } = useGetFilmDetails(Number(id));

  const { mutate: updateMutate, isLoading: isUpdateLoading } = useMutation(doUpdateFilm, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['films', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`films/${id}`, undefined],
      });
      message.info('Update film successfully!');
      navigate(`/films`, { replace: true });
    },
    onError: () => {
      message.error('Update film failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  useEffect(() => {
    refetchFilmDetail();
    localStorage.setItem('from', 'film-update');
  }, []);

  return (
    <Spin spinning={isLoading ?? isUpdateLoading}>
      <div className="films-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Film</h2>
            </div>
            <FilmForm handleSubmit={handleSubmit} film={data} filmId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default FilmUpdatePage;
