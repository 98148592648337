import React from 'react';
import { Button, message } from 'antd';
import './DisplayLink.scss';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { replaceTextWithDots } from '../../../utils/global';

type Props = {
  link?: string;
};

const DisplayLinkComponent: React.FC<Props> = ({ link }) => {
  const onCopyClick = () => {
    message.info('Coppied');
  };

  const getLink = (link: string) => {
    return `${process.env.REACT_APP_ROOT_PATH}/salesheet/${link}` || '';
  };

  return (
    <div className="display-link-section">
      <Link to={`/salesheet/${link}`} target="_blank">
        {replaceTextWithDots(link || '', 7)}
      </Link>
      <CopyToClipboard text={getLink(link || '')} onCopy={onCopyClick}>
        <Button>Copy</Button>
      </CopyToClipboard>
    </div>
  );
};

export default DisplayLinkComponent;
