import React from 'react';
import { FilmI } from '../../../interfaces/film';
import TableBase, { Props as TableProps } from './Base';
import { User } from '../../../interfaces/user';
import { SalesheetI } from '../../../interfaces/salesheet';
import GetPassword from '../../../pages/salesheet/components/GetPassword';
import DisplayLinkComponent from '../../../pages/salesheet/components/DisplayLink';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const SalesheetTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Film',
      dataIndex: 'salesSheetDetails',
      width: 150,
      render: (films: FilmI[]) => {
        return (
          <ul style={{ paddingLeft: 16 }}>
            {films?.map((film: FilmI, index: number) => (
              <li key={index} className="text-uppercase">
                {film.title} {film.titleCN && `《 ${film.titleCN} 》`}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: 150,
      render: (user: User) => {
        return `${user.firstName} ${user.lastName}`;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 150,
      render: (text: string) => {
        const date = new Date(text);
        return date.toLocaleDateString();
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      width: 200,
      render: (link: string) => {
        return <DisplayLinkComponent link={link} />;
      },
    },
    {
      title: 'Password',
      width: 200,
      render: (record: SalesheetI) => {
        return <GetPassword salesheet={record} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default SalesheetTable;
