import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { QueryParams } from '../../interfaces';
import { PAGE_SIZE } from '../../shared/constants';
import { getAuditLogsList } from '../../api/auditLogs';
import AuditsTable from '../../shared/components/tables/Audits';

const AuditLogsPage: React.FC = () => {
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_SIZE });
  const { data, isLoading } = useQuery(['audits', 'list', queryParams], () => getAuditLogsList(queryParams));

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: any) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    setQueryParams({ start: Number(offset), count: PAGE_SIZE });
  };

  return (
    <div className="audits-container">
      <div className="page-container">
        <div className="page-header">
          <div></div>
          <div className="pagee-header-actions"></div>
        </div>
        <div className="page-content">
          <AuditsTable
            rowKey="id"
            loading={isLoading}
            pagination={pagination}
            dataSource={data?.data}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditLogsPage;
