export const APP_NAME = 'Clover Film';
export const TOKEN_NAME = '_clover_film_session';
export const PAGE_SIZE = 25;
export const DATETIME_FORMAT_FRONTEND = 'h:mm A, MMM Do, YYYY';
export const PICKUPDATE_FORMAT_FRONTEND = 'ddd, DD MMM, YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY/MM/DD';
export const DATE_FORMAT_QUERY = 'YYYY-MM-DD';
export const DATETIME_FORMAT_QUERY = 'YYYY-MM-DD hh:mm:ss';
export const DATETIME_FORMAT = 'DD-MMM-YY h:mm A';
export const DATE_FORMAT_FRONTEND = 'DD/MM/YYYY';

export const PASSWORD_TIP =
  'At least 1 uppercase, 1 lowercase, 1 numeric, 1 special character, 12 characters or longer.';

export const SUPER_ADMIN_ACCESS_ROLE = ['SUPER_ADMIN'];
export const FINANCE_ACCESS_ROLE = ['FINANCE', 'DATABASE', 'SALE'];
export const DATABASE_ACCESS_ROLE = ['DATABASE'];
export const SALE_ACCESS_ROLE = ['SALE'];

export const SUPER_ADMIN_MODULES = ['/users', '/audits'];
export const FINANCE_MODULES = ['/films', '/billings', '/customers', '/licenses', '/rights', '/suppliers', '/audits'];
export const DATABASE_MODULES = ['/films', '/customers', '/licenses', '/rights', '/suppliers'];
export const SALE_MODULES = ['/salesheets'];

export const USER_ROLES = [
  { name: 'Super Admin', value: 'super_admin' },
  { name: 'Finance', value: 'finance' },
  { name: 'Sale', value: 'sale' },
  { name: 'Database', value: 'database' },
];

export const TERRITORY_TYPES = [
  { name: 'Exclusive', value: 'exclusive' },
  { name: 'Non Exclusive', value: 'non-exclusive' },
];

export const FinanceAccessControl = [
  { films: ['C', 'R', 'U', 'D'] },
  { billings: ['C', 'R', 'U', 'D'] },
  { customers: ['C', 'R', 'U', 'D'] },
  { licenses: ['C', 'R', 'U', 'D'] },
  { rights: ['C', 'R', 'U', 'D'] },
  { suppliers: ['C', 'R', 'U', 'D'] },
  { audits: ['R'] },
];

export const DatabaseAccessControl = [
  { films: ['R', 'U'] },
  { billings: ['C', 'R', 'U', 'D'] },
  { customers: ['R'] },
  { licenses: ['R', 'U'] },
  { rights: ['C', 'R', 'U', 'D'] },
  { suppliers: ['R'] },
];

export const SalesheetAccessControl = [{ salesheets: ['C', 'R', 'U', 'D'] }];

export const SuperAdminAccessControl = [{ users: ['C', 'R', 'U', 'D'] }, { audits: ['R'] }];

export const getSEACountries = ['BN', 'KH', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'TL', 'VN'];

export const SEACountries = [
  'Brunei',
  'Cambodia',
  'Indonesia',
  'Laos',
  'Malaysia',
  'Myanmar',
  'Philippines',
  'Singapore',
  'Thailand',
  'East Timor',
  'Vietnam',
];

export const SEACountriesWithLabel = [
  { value: 'BN', label: 'Brunei' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'TL', label: 'East Timor' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'LA', label: 'Laos' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'PH', label: 'Philippines' },
  { value: 'SG', label: 'Singapore' },
  { value: 'TH', label: 'Thailand' },
  { value: 'VN', label: 'Vietnam' },
];
