import { Button, Modal } from 'antd';
import React from 'react';
import './ConfirmButton.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
  label?: string;
  icon?: any;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  style?: any;
  content: string;
  color?: 'primary' | 'secondary';
  confirmIcon?: any;
  className?: string;
  onCancel?: () => void;
  onOk?: () => void;
};

const ConfirmButton: React.FC<Props> = ({
  label,
  icon,
  htmlType,
  loading,
  style,
  content,
  color,
  confirmIcon,
  className,
  onOk,
  onCancel,
}) => {
  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      icon: confirmIcon ? confirmIcon : <ExclamationCircleOutlined />,
      content: content,
      onOk() {
        onOk && onOk();
      },
      onCancel() {
        onCancel && onCancel();
      },
    });
  }
  return (
    <Button
      type="default"
      className={`${color} ${className}`}
      htmlType={htmlType}
      loading={loading}
      style={style}
      onClick={showConfirm}
    >
      {label}
      {icon}
    </Button>
  );
};

export default ConfirmButton;
