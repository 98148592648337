import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import { LoginResponseI } from '../../../interfaces/auth';
import MainSiderContent from '../siderContents/Main';

type Props = {
  currentUser: LoginResponseI;
};

const MainLayout: React.FC<Props> = ({ currentUser }) => {
  return (
    <Layout className="main-layout">
      <MainSiderContent currentUser={currentUser} />
      <Layout className="site-layout">
        <HeaderMain currentUser={currentUser} />
        <Outlet context={currentUser} />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
