import React from 'react';
import { User } from '../../../interfaces/user';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../../utils/global';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const AuditsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      render: (type: string) => {
        return <span className="text-capitalize">{type}</span>;
      },
    },
    {
      title: 'Reference Type',
      dataIndex: 'referenceType',
      width: 150,
    },
    {
      title: 'Reference ID',
      dataIndex: 'referenceId',
      width: 150,
    },
    {
      title: 'Reference Name',
      dataIndex: 'referenceName',
      width: 150,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: 150,
      render: (createdBy: User) => {
        return (
          <span className="text-capitalize">
            {createdBy?.firstName} {createdBy?.lastName}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 150,
      render: (createdAt: string) => {
        return DateFormat(createdAt);
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default AuditsTable;
