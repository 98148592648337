import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { doCreateBilling } from '../../api/billings';
import { BillingI } from '../../interfaces/billing';
import BillingForm from '../../shared/components/forms/billing/Form';

const BillingNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(doCreateBilling, {
    onSuccess: async () => {
      message.info('Create billing successfully!');
      queryClient.invalidateQueries({
        queryKey: ['billings', 'list'],
      });
      navigate(`/billings`, { replace: true });
    },
    onError: () => {
      message.error('Create billing failed! Please try again');
    },
  });

  const handleSubmit = async (values: BillingI) => {
    createMutate(values);
  };

  return (
    <div className="billing-new-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Billing</h2>
          </div>
          <BillingForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default BillingNewPage;
