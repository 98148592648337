import React from 'react';
import TableBase, { Props as TableProps } from './Base';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const CustomersTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      with: 200,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default CustomersTable;
