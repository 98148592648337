import { message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import CancelIcon from '../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../shared/svgs/EditIcon';
import { doDeleteSupplier, useGetSupplierDetails } from '../../api/suppliers';
import { CurrentUserI } from '../../interfaces/auth';
import { getUsername, handleAccessControl } from '../../utils/global';
import BackButton from '../../shared/components/buttons/BackButton';
import SupplierLicensesTable from '../../shared/components/tables/SuppliesLicenses';
import { DATE_FORMAT_FRONTEND } from '../../shared/constants';
import dayjs from 'dayjs';

const SupplierDetailsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles, 'suppliers');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: supplier, isLoading, refetch: refetchSupplier } = useGetSupplierDetails(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(doDeleteSupplier, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['suppliers', 'list'],
      });
      navigate(`/suppliers`, { replace: true });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  useEffect(() => {
    refetchSupplier();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div id="supplier-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Supplier Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Name</label>
                <span className="value">{supplier?.name}</span>
              </div>
              <div className="item">
                <label>Created At</label>
                <span className="value">{dayjs(supplier?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Created By</label>
                <span className="value">{supplier?.createdBy ? getUsername(supplier?.createdBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Updated At</label>
                <span className="value">
                  {supplier?.updatedBy ? dayjs(supplier?.updatedAt).format(DATE_FORMAT_FRONTEND) : '-'}
                </span>
              </div>
              <div className="item">
                <label>Updated By</label>
                <span className="value">{supplier?.updatedBy ? getUsername(supplier?.updatedBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Remark</label>
                <span className="value">{supplier?.remark}</span>
              </div>
            </div>
            <div className="page-content-footer">
              {access.isCanUpdate && (
                <Link to={`/suppliers/${id}/edit`}>
                  <PrimaryButton label="Edit" icon={<EditIcon />} />
                </Link>
              )}
              {access.isCanDelete && <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />}
              <BackButton label="Cancel" icon={<CancelIcon />} />
            </div>
          </div>
          {supplier?.films && (
            <div className="page-content">
              <div className="page-content-header">
                <h2 className="title-page">Licenses/Films</h2>
              </div>
              <SupplierLicensesTable rowKey="id" loading={isLoading} pagination={false} dataSource={supplier?.films} />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default SupplierDetailsPage;
