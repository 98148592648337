import { message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { doDeleteFilm, useGetFilmDetails } from '../../api/films';
import './FilmDetails.scss';
import FilmDetailsComponent from '../../shared/components/FilmDetails';
import { AccessControlI } from '../../interfaces';
import { handleAccessControl } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import SoldLicensesTable from '../../shared/components/tables/SoldLicenses';

const FilmNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access: AccessControlI = handleAccessControl(currentUser.roles, 'films');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: film, isLoading, refetch: refetchFilmDetail } = useGetFilmDetails(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(doDeleteFilm, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['films', 'list'],
      });
      navigate(`/films`, { replace: true });
    },
  });

  useEffect(() => {
    refetchFilmDetail();
    localStorage.setItem('from', 'film-detail');
  }, []);

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  return (
    <Spin spinning={isLoading}>
      <div id="film-details-container">
        <div className="page-container">
          <div className="page-content">
            <FilmDetailsComponent
              film={film}
              hideActionButtons={true}
              handleDelete={handleDelete}
              access={access}
              currentUser={currentUser}
            />
          </div>
          {film?.soldLicenses && (
            <div className="page-content" style={{ padding: 16, background: '#ffffff' }}>
              <div className="page-content-header">
                <h2 className="title-page">Sold Licenses</h2>
              </div>
              <SoldLicensesTable rowKey="id" loading={isLoading} pagination={false} dataSource={film?.soldLicenses} />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default FilmNewPage;
