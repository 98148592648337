import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import { SupplierI } from '../../../../interfaces/supplier';
import BackButton from '../../buttons/BackButton';

type Props = {
  isFetching?: boolean;
  supplierId?: number;
  supplier?: SupplierI;
  handleSubmit(_: any): void;
};

const SupplierForm: React.FC<Props> = ({ supplier, supplierId, isFetching, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: SupplierI) => {
    handleSubmit(values);
  };

  useEffect(() => {
    if (supplier && supplier.id === supplierId) {
      form.setFieldsValue(supplier);
    }
  }, [supplier]);

  return (
    <div className="supplier-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
              <Input type="text" placeholder="Required" />
            </Form.Item>
            <Form.Item label="Remark" name="remark">
              <Input.TextArea rows={3} placeholder="Optional" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={supplierId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <BackButton label={'Cancel'} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SupplierForm;
