import { handleFilterParams } from '../utils/global';
import { api } from '../shared/api';

export const getAuditLogsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`auditLogs?${newParams}`);
  return response;
};
