import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryParams } from '../../../interfaces';
import {
  doDeleteMultipleNotifications,
  doDeleteNotification,
  doGetNotificationsList,
  doUpdateNotification,
} from '../../../api/notifications';
import { Badge, Button, Checkbox, Divider, Popover } from 'antd';
import { ReactSVG } from 'react-svg';
import './Notifications.scss';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationI } from '../../../interfaces/notification';
import { DateFormat } from '../../../utils/global';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import DeleteForeverIcon from '../../svgs/DeleteForeverIcon';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
const PAGE_COUNT = 20;

const CheckboxGroup = Checkbox.Group;

const Notifications: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<number[]>([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [viewUnreadOnly, setViewUnreadOnly] = useState<boolean>(false);
  const [list, setList] = useState<NotificationI[]>([]);
  const notificationsEndRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<QueryParams>({ start: 0, count: PAGE_COUNT });
  const { data }: any = useQuery(['notifications', 'list', queryParams], () => doGetNotificationsList(queryParams));
  const totalUnread = data?.data ? data?.totalOfUnread : 0;

  useEffect(() => {
    if (data?.data) {
      setList(data?.data);
      if (data?.data?.length >= data?.total) {
        setHasMore(false);
      } else setHasMore(true);
    }
  }, [data]);

  const fetchMoreData = () => {
    if (data?.data?.length >= data?.total) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      if (viewUnreadOnly) {
        setQueryParams({ start: 0, count: data?.count + PAGE_COUNT, filter: { isRead: false } });
      } else {
        setQueryParams({ start: 0, count: data?.count + PAGE_COUNT, filter: undefined });
      }
    }, 1000);
    const previousScrollPosition = window.scrollY;
    setTimeout(() => {
      window.scrollTo(0, previousScrollPosition);
    }, 100);
  };

  const handleSelect = () => {
    setIsSelect(!isSelect);
    setItemSelected([]);
    setIndeterminate(false);
  };

  const onCheckboxChange = (event: any) => {
    const { value, checked } = event.target;
    if (checked) {
      setItemSelected((prevState) => [...prevState, value]);
    } else {
      const idxValue = itemSelected.findIndex((item) => item === value);
      const newArr = [...itemSelected.slice(0, idxValue), ...itemSelected.slice(idxValue + 1)];
      setItemSelected(newArr);
    }
    setIndeterminate(!!itemSelected.length && itemSelected.length < data?.data?.length);
  };

  const hasSelected = itemSelected.length > 0;

  const onCheckAllChange = (event: CheckboxChangeEvent) => {
    setIndeterminate(false);
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      const ids = data?.data?.map((item: NotificationI) => item.id);
      setItemSelected(ids);
    } else setItemSelected([]);
  };

  const { mutateAsync: mutationMultipleDelete } = useMutation(doDeleteMultipleNotifications, {
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'list'],
      });
    },
  });

  const { mutateAsync: mutationDelete } = useMutation(doDeleteNotification, {
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'list'],
      });
    },
  });

  const { mutateAsync: mutationDone } = useMutation(doDeleteNotification, {
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'list'],
      });
    },
  });

  const handleDelete = () => {
    mutationMultipleDelete(itemSelected);
  };

  const onDeleteClick = (id: number) => {
    mutationDelete(id);
  };

  const onDoneClick = (id: number) => {
    mutationDone(id);
  };

  const { mutate: updateIsReadMutate } = useMutation(doUpdateNotification, {
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', 'list'],
      });
    },
  });

  const handleRead = (notification: NotificationI) => {
    updateIsReadMutate(notification.id);
    navigate(`/${notification.url}`, { replace: true });
  };

  const handleViewUnreadOnly = () => {
    if (!viewUnreadOnly) {
      setQueryParams({ start: 0, count: PAGE_COUNT, filter: { isRead: false } });
      setViewUnreadOnly(true);
    } else {
      setQueryParams({ start: 0, count: PAGE_COUNT, filter: undefined });
      setViewUnreadOnly(false);
    }
    setHasMore(true);
  };

  return (
    <Popover
      placement="bottom"
      trigger={'click'}
      popupVisible={visible}
      onOpenChange={(visible) => setVisible(visible)}
      content={
        <div className="notifications-container">
          <div className="header">
            <div className="title">
              <span>Notifications</span>
              <Button type="text" onClick={() => setVisible(false)}>
                <ReactSVG src="/icons/close-icon.svg" />
              </Button>
            </div>
            <div className="actions">
              <div className="actions-top">
                <Button type="text" className="mark-all-as-read" onClick={handleSelect}>
                  Select
                </Button>
                <Button type="text" className="view-unread-only" onClick={handleViewUnreadOnly}>
                  {!viewUnreadOnly ? 'View Unread Only' : 'View All'}
                </Button>
              </div>
              {isSelect && (
                <>
                  <Divider />
                  <div className="actions-bottom">
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                      Check all
                    </Checkbox>
                    <div>
                      {hasSelected
                        ? `Seleted ${itemSelected.length} ${itemSelected.length > 1 ? 'items' : 'item'}`
                        : ''}
                      {itemSelected.length > 0 && (
                        <Button type="text" className="multiple-delete-notifications" onClick={handleDelete}>
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="items">
            {list.length > 0 ? (
              <>
                {list?.map((notification: NotificationI) => {
                  return (
                    <div key={notification.id}>
                      <CheckboxGroup value={itemSelected}>
                        {isSelect ? <Checkbox value={notification.id} onChange={onCheckboxChange} /> : null}
                        <div className={`notification ${notification.isRead ? 'read' : 'unread'} `}>
                          <div className="notification-wrap" onClick={() => handleRead(notification)}>
                            <div className="icon">
                              <ReactSVG className="svg-icon" src={`/icons/notifications/${notification.type}.svg`} />
                            </div>
                            <div className="main">
                              <div className="title-date">
                                <div className="title">{notification.title}</div>
                                <div className="datetime">{DateFormat(notification.createdAt)}</div>
                              </div>
                              <div className="content-read">
                                <div className="read">
                                  {!notification.isRead && <ReactSVG src="/icons/unread.svg" />}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="delete-notification-button">
                            <div className="wrap">
                              <Button onClick={() => onDoneClick(notification.id)} className="done-button">
                                <CheckCircleIcon />
                              </Button>
                              <Button onClick={() => onDeleteClick(notification.id)} className="delete-button">
                                <DeleteForeverIcon />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </CheckboxGroup>
                    </div>
                  );
                })}
                {hasMore && (
                  <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button onClick={fetchMoreData}>Load More</Button>
                  </div>
                )}
                {!hasMore && (
                  <p style={{ textAlign: 'center', paddingTop: 15 }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                )}
                <div ref={notificationsEndRef} />
              </>
            ) : (
              <p style={{ textAlign: 'center', paddingTop: 15 }}>
                <b>{totalUnread === 0 ? 'There are no unread messages' : 'There are no messages'}</b>
              </p>
            )}
          </div>
        </div>
      }
    >
      <Badge count={totalUnread || 0} overflowCount={99}>
        <span className="anticon">
          <ReactSVG src="/icons/bell-badge.svg" />
        </span>
      </Badge>
    </Popover>
  );
};

export default Notifications;
