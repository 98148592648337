import {
  DATABASE_MODULES,
  DATETIME_FORMAT,
  DATE_FORMAT_FRONTEND,
  DatabaseAccessControl,
  FINANCE_MODULES,
  FinanceAccessControl,
  SALE_MODULES,
  SEACountries,
  SUPER_ADMIN_MODULES,
  SalesheetAccessControl,
  SuperAdminAccessControl,
} from './../shared/constants/index';
import { pgpEncrypt } from '../helpers/openPGPHelper';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from '../shared/constants';
import * as qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import { RightI } from '../interfaces/right';
import { AccessControlI } from '../interfaces';
import { FilmI } from '../interfaces/film';
import { CurrentUserI } from '../interfaces/auth';
import countryList from 'react-select-country-list';
import { User } from '../interfaces/user';

dayjs.extend(customParseFormat);

const openPgpState = process.env.REACT_APP_PGP_STATE;

export const handleEncryptData = async (params: any) => {
  if (openPgpState === 'on') {
    let parseToString = JSON.stringify(params);
    const pgpCipherText = await pgpEncrypt(parseToString);
    return { data: pgpCipherText };
  } else {
    return params;
  }
};

export default function parseToken(authToken: string) {
  const token = authToken || Cookies.get(TOKEN_NAME);
  let user = {};
  if (!token) return undefined;
  try {
    user = jwtDecode(token);
  } catch (e) {
    console.log(e);
  }
  return user;
}

export const humanizeText = (text: string) => {
  return text?.replace(/[_]/g, ' ');
};

export const handleFilterParams = (params: any) => {
  return qs.stringify(params, { encode: false, skipNulls: true });
};

export const convertDayJSToISOString = (dayjsString: Dayjs) => {
  return dayjs(dayjsString);
};

export const DateFormat = (date: any) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(DATE_FORMAT_FRONTEND);
};

export const DateTimeFormat = (datetime: any) => {
  if (datetime === null) return;
  return dayjs(datetime).format(DATETIME_FORMAT);
};

export const convertDateTimeToTimestamp = (datetime: Dayjs): number => {
  return datetime ? datetime.unix() * 1000 : setStartTime(dayjs()).unix() * 1000;
};

export const setStartTime = (date: dayjs.Dayjs): dayjs.Dayjs => {
  return dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
};

export const setEndTime = (date: dayjs.Dayjs): dayjs.Dayjs => {
  return dayjs(date).set('hour', 23).set('minute', 59).set('second', 59);
};

export const getCurrencyCode = (code: string) => {
  if (code === 'CNY') {
    return 'RMB';
  } else return code;
};

export const deleteEmptyChildren = (node: any) => {
  if (node.children && node.children.length === 0) {
    delete node.children;
  } else if (node.children) {
    node.children.forEach((childNode: any) => deleteEmptyChildren(childNode));
  }
  return node;
};

export const replaceTextWithDots = (str: string, startIndex: number): string => {
  if (startIndex >= str.length) {
    return str;
  }

  return str.slice(0, startIndex) + '...';
};

export const handleDisplayFilterFields = (searchString: string) => {
  const newSearch = searchString.replace('?', '');
  const oldSearch: any = qs.parse(newSearch);
  return oldSearch?.filter || undefined;
};

export const convertToRightTreeList = (data: RightI[]) => {
  const newData: any[] = data.map((item: RightI) => {
    return { id: item.id, title: item.name, value: item.id, parentId: item.parentId };
  });

  const newRight = newData?.map((item: any) => {
    return { ...item, children: null };
  });

  _(newRight).forEach((f) => {
    const child = _(newRight)
      .filter((g) => g.parentId === f.id)
      .value();

    if (child.length !== 0) {
      f.children = child;
    } else {
      delete f.children;
    }
  });

  const result = _(newRight)
    .filter((f) => f.parentId === null)
    .value();

  return result;
};

export const hasCommonElementArray = (array1: string[], array2: string[]) => {
  const elements = array1?.filter((element: string) => array2?.includes(element));
  if (elements && Object.keys(elements).length > 0) return true;
  return false;
};

export const mergeUniqueStringArray = (arr1: string[], arr2: string[]) => {
  const merged = [...arr1];
  for (const item of arr2) {
    if (!merged.some((existingItem) => existingItem === item)) {
      merged.push(item);
    }
  }

  return merged;
};

export const getModulesByRoles = (roles: string[]) => {
  let accessList: string[] = ['/'];
  roles.forEach((role: string) => {
    switch (role) {
      case 'SUPER_ADMIN':
        accessList = mergeUniqueStringArray(accessList, SUPER_ADMIN_MODULES);
        break;
      case 'FINANCE':
        accessList = mergeUniqueStringArray(accessList, FINANCE_MODULES);
        break;
      case 'DATABASE':
        accessList = mergeUniqueStringArray(accessList, DATABASE_MODULES);
        break;
      case 'SALE':
        accessList = mergeUniqueStringArray(accessList, SALE_MODULES);
        break;
      default:
        accessList = [];
        break;
    }
  });
  return accessList;
};

export const handleGetAccessControlList = (roles: string[], module: string): string[] => {
  let access: string[] = [];
  roles.forEach((role: string) => {
    switch (role) {
      case 'FINANCE':
        const financeList = FinanceAccessControl.filter((item: any) => item[module]);
        if (Object.keys(financeList).length > 0) {
          access = mergeUniqueStringArray(access, Object.values(financeList[0])[0]);
        }
        break;
      case 'DATABASE':
        const databaseList = DatabaseAccessControl.filter((item: any) => item[module]);
        if (Object.keys(databaseList).length > 0) {
          access = mergeUniqueStringArray(access, Object.values(databaseList[0])[0]);
        }
        break;
      case 'SALE':
        const saleSheetList = SalesheetAccessControl.filter((item: any) => item[module]);
        if (Object.keys(saleSheetList).length > 0) {
          access = mergeUniqueStringArray(access, Object.values(saleSheetList[0])[0]);
        }
        break;
      case 'SUPER_ADMIN':
        const superAdminList = SuperAdminAccessControl.filter((item: any) => item[module]);
        if (Object.keys(superAdminList).length > 0) {
          access = mergeUniqueStringArray(access, Object.values(superAdminList[0])[0]);
        }
        break;
    }
  });
  return access;
};

export const handleAccessControl = (roles: string[], module: string): AccessControlI => {
  const access = handleGetAccessControlList(roles, module);
  return {
    isCanCreate: access.includes('C'),
    isCanUpdate: access.includes('U'),
    isCanRead: access.includes('R'),
    isCanDelete: access.includes('D'),
  };
};

export const getModuleFromPathname = (pathname: string) => {
  const splitString: string[] = pathname.split('/');
  return splitString[1] || '';
};

export const handleCheckAuthorize = (roles: string[], pathname: string): boolean => {
  const module: string = getModuleFromPathname(pathname);
  const accessList: string[] = handleGetAccessControlList(roles, module);
  const pathnameSplit: string[] = pathname.split('/');
  if (pathnameSplit[1] === module) {
    if (pathnameSplit[2] === 'new') {
      if (accessList.includes('C')) return true;
      return false;
    }

    if (pathnameSplit[3] === 'edit') {
      if (accessList.includes('U')) return true;
      return false;
    }

    if (accessList.includes('R')) {
      return true;
    }
    return false;
  } else return false;
};

export const mergeAndUniqueTwoArray = (arr1: any[], arr2: any[]) => {
  const merged = [...arr1];

  for (const item of arr2) {
    if (!merged.some((existingItem) => existingItem.id === item.id)) {
      merged.push(item);
    }
  }

  return merged;
};

export const checkIsSEACountries = (countryCodes: string[]): boolean => {
  return SEACountries.every((country) => countryCodes.includes(country.toLowerCase()));
};

export const handleGetCountriesWithSEA = (countries: string[]): string[] => {
  const isHasSEACountries: boolean = checkIsSEACountries(countries);
  const lowerCaseSEACountries = SEACountries.map((country) => country.toLowerCase());
  return isHasSEACountries
    ? ['SEA', ...countries.filter((country: string) => !lowerCaseSEACountries.includes(country.toLowerCase()))]
    : countries;
};

export const handleGetCoShareValue = (film: FilmI, currentUser?: CurrentUserI) => {
  if (!film.coShareName || !film.coShareValue) return [''];
  const nameSplit = film.coShareName.split(',');
  const valueSplit = film.coShareValue.split(',');
  return nameSplit.map((item: string, index: number) => {
    return `${item ? item?.trim() : ''} ${valueSplit[index] ? `- ${valueSplit[index]?.trim()}` : ''}${
      (valueSplit[index] && currentUser?.roles.includes('FINANCE')) || currentUser?.roles.includes('SUPER_ADMIN')
        ? '%'
        : ''
    }`;
  });
};

export function capitalizeFirstLetterOfEachWord(str: string) {
  const words = str.split(' ');

  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
}

export const replaceCountryName = (originalName: string) => {
  switch (originalName.toLowerCase()) {
    case 'Timor-Leste'.toLowerCase():
      return 'East Timor';
    case 'Viet Nam'.toLowerCase():
      return 'Vietnam';
    case 'Brunei Darussalam'.toLowerCase():
      return 'Brunei';
    case `lao people's democratic republic`.toLowerCase():
      return 'Laos';
    case 'Bolivia, Plurinational State of'.toLowerCase():
      return 'Bolivia';
    case 'Bonaire, Sint Eustatius and Saba'.toLowerCase():
      return 'Bonaire';
    case 'Congo, Democratic Republic of the'.toLowerCase():
      return 'Congo';
    case 'Iran, Islamic Republic of'.toLowerCase():
      return 'Iran';
    case "Korea, Democratic People's Republic of".toLowerCase():
      return 'North Korea';
    case 'Korea, Republic of'.toLowerCase():
      return 'South Korea';
    case 'Micronesia, Federated States of'.toLowerCase():
      return 'Micronesia';
    case 'Moldova, Republic of'.toLowerCase():
      return 'Moldova';
    case 'Palestine, State of'.toLowerCase():
      return 'Palestine';
    case 'Saint Helena, Ascension and Tristan da Cunha'.toLowerCase():
      return 'Saint Helena';
    case 'Tanzania, United Republic of'.toLowerCase():
      return 'Tanzania';
    case 'Venezuela, Bolivarian Republic of'.toLowerCase():
      return 'Venezuela';
    case 'Taiwan, Province Of China'.toLowerCase():
      return 'Taiwan';
    case 'Virgin Islands, British'.toLocaleLowerCase():
      return 'Virgin Islands British';
    case 'Virgin Islands, U.S.'.toLocaleLowerCase():
      return 'Virgin Islands U.S.';
    default:
      return originalName;
  }
};

export const getCountriesList = (haveSEA = false, haveWorldWide = false) => {
  const countries = countryList().getData();
  const updatedLabel = countries
    .map((countryOption) => ({
      ...countryOption,
      label: replaceCountryName(countryOption.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  if (haveSEA && haveWorldWide)
    return [
      { value: 'WORLDWIDE', label: 'WORLDWIDE' },
      { value: 'SEA', label: 'SEA' },
    ].concat(updatedLabel);

  if (haveSEA) return [{ value: 'SEA', label: 'SEA' }].concat(updatedLabel);
  if (haveWorldWide) return [{ value: 'WORLDWIDE', label: 'WORLDWIDE' }].concat(updatedLabel);
  return updatedLabel;
};

export const getCountryLabel = (countriesList: { value: string; label: string }[], countryCode: string) => {
  return countriesList?.filter(({ value }) => value === countryCode)?.[0]?.label || countryCode;
};

export const getCountryValue = (countriesList: { value: string; label: string }[], countryLabel: string) =>
  countriesList.filter(({ label }) => label.toLowerCase() === countryLabel.toLowerCase())?.[0]?.value || countryLabel;

export function isWorldWide(key: number, selectedRegion: { [key: number]: string[] } | null) {
  if (selectedRegion == null || selectedRegion[key] == null) return false;
  return selectedRegion[key].some((region) => region === 'WORLDWIDE');
}

export const getUsername = (user?: User) => {
  if (!user) return '';
  return user.firstName + ' ' + user?.lastName;
};

export const getNestedQueryValues = (params: any, key: string) => {
  const values = [];
  const escapedKey = key.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`^${escapedKey}\\[(\\d+)\\]$`);
  for (const [paramKey, value] of params.entries()) {
    const match = paramKey.match(regex);
    if (match) {
      values[Number(match[1])] = value;
    }
  }
  return values;
};

export const replaceWithSEA = (countries: string[]) => {
  const SEA_COUNTRIES = ['BN', 'KH', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'TL', 'VN'];
  const seaSet = new Set(SEA_COUNTRIES);

  const seaCountriesInList = countries.filter((country) => seaSet.has(country));
  const nonSeaCountries = countries.filter((country) => !seaSet.has(country));

  if (seaCountriesInList.length === SEA_COUNTRIES.length) {
    return ['SEA', ...nonSeaCountries];
  } else {
    return [...seaCountriesInList, ...nonSeaCountries];
  }
};
