import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { SalesheetCreateParams, SalesheetI } from '../interfaces/salesheet';

export const useGetSalesheetsList = () => useFetch<GetListResponse<SalesheetI>>('salesheets');

export const useGetSalesheelDetails = (id: number) => useFetch<SalesheetI>(`salesheets/${id}`);

export const useSalesheetFilm = (updater: (oldData: SalesheetI[], newData: SalesheetI) => SalesheetI[]) => {
  return usePost<SalesheetI[], SalesheetI>(pathToUrl('salesheets'), undefined, updater);
};

export const doGetSalesheetsList = async ({
  start,
  count,
  filter,
}: {
  start?: number;
  count?: number;
  filter?: any;
}) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: Partial<GetListResponse<SalesheetI>> = await api.get(`salesheets?${newParams}`);
  return response;
};

export const doCreateSalesheet = async (values: SalesheetCreateParams) => {
  const { data: response }: any = await api.post('salesheets', values);
  return response.data;
};

export const doDeleteSalesheet = async (id: number) => {
  const { data: response }: any = await api.delete(`salesheets/${id}`);
  return response.data;
};

export const doGetSalesheetPassword = async (id: number) => {
  const { data: response }: any = await api.get(`salesheets/${id}/viewPassword`);
  return response.data;
};

export const doCustomerEnterPassword = async (params: { id: string; body: any }) => {
  const { data: response }: any = await api.post(`sale-sheets/${params.id}/unlock`, params.body);
  return response;
};

export const doDuplicateSalesheet = async (id: number) => {
  const { data: response }: any = await api.post(`salesheets/${id}/duplicate`, {});
  return response.data;
};

export const doExportSalesheet = async (params: number) => {
  const { data: response }: any = await api.postBlob(`salesheets/exportXlsx`, params);
  if (response) {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Salesheets-${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
  return response.data;
};

export const doExportSalesheetPdf = async (params: number) => {
  const { data: response }: any = await api.getBlob(`salesheets/${params}/exportPdf`);
  if (response) {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Salesheets-${Date.now()}.pdf`);
    document.body.appendChild(link);
    link.click();
  }
  return response.data;
};
