import React from 'react';

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const PublishIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height || 24}px`}
      viewBox="0 0 24 24"
      width={`${width || 24}px`}
      fill={`${color || 'currentColor'}`}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z" />
    </svg>
  );
};
export default PublishIcon;
