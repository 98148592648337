import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { SupplierI } from '../interfaces/supplier';

export const useGetSuppliers = () => useFetch<GetListResponse<SupplierI>>('suppliers');

export const useGetSupplierDetails = (id: number) =>
  useFetch<SupplierI>(`suppliers/${id}`, {}, { refetchOnMount: true });

export const useCreateSupplier = (updater: (oldData: SupplierI[], newData: SupplierI) => SupplierI[]) => {
  return usePost<SupplierI[], SupplierI>(pathToUrl('suppliers'), undefined, updater);
};

export const getSuppliersList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`suppliers?${newParams}`);
  return response;
};

export const doCreateSupplier = async (values: SupplierI) => {
  const { data: response }: any = await api.post('suppliers', values);
  return response.data;
};

export const doUpdateSupplier = async ({ id, ...values }: { id: string; values: any }) => {
  const { data: response }: any = await api.patch(`suppliers/${id}`, values);
  return response.data;
};

export const doDeleteSupplier = async (id: number) => {
  const { data: response }: any = await api.delete(`suppliers/${id}`);
  return response.data;
};
