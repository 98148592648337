import { pathToUrl } from './../utils/router';
import { User } from './../interfaces/user';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { RightI } from '../interfaces/right';

export const useGetRightsList = () => useFetch<GetListResponse<RightI>>('type-of-rights');

export const useGetRightDetails = (id: number) => useFetch<RightI>(`type-of-rights/${id}`);

export const useCreateRight = (updater: (oldData: RightI[], newData: RightI) => RightI[]) => {
  return usePost<RightI[], RightI>(pathToUrl('type-of-rights'), undefined, updater);
};

export const getRightsList = async ({ start, count, filter }: { start?: number; count?: number; filter?: any }) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: any = await api.get(`type-of-rights?${newParams}`);
  return response;
};

export const doCreateRight = async (values: RightI) => {
  const { data: response }: any = await api.post('type-of-rights', values);
  return response.data;
};

export const doUpdateRight = async ({ id, ...values }: { id: string; values: User }) => {
  const { data: response }: any = await api.patch(`type-of-rights/${id}`, values);
  return response.data;
};

export const doDeleteRight = async (id: number) => {
  const { data: response }: any = await api.delete(`type-of-rights/${id}`);
  return response.data;
};
