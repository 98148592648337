import { message, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { doDeleteBilling, useGetBillingDetails } from '../../api/billings';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import { DATE_FORMAT_FRONTEND } from '../../shared/constants';
import CancelIcon from '../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../shared/svgs/EditIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { getCurrencyCode, getUsername, handleAccessControl } from '../../utils/global';
import BackButton from '../../shared/components/buttons/BackButton';

const BillingNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles, 'billings');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: billing, isLoading } = useGetBillingDetails(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(doDeleteBilling, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['billings', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`billings/${id}`, undefined],
      });
      navigate(`/billings`, { replace: true });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  return (
    <Spin spinning={isLoading}>
      <div id="billing-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Billing Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Film</label>
                <span className="value">{billing?.film?.title}</span>
              </div>
              <div className="item">
                <label>Customer</label>
                <span className="value">{billing?.customer?.name}</span>
              </div>
              <div className="item">
                <label>Amount</label>
                <span className="value">
                  {
                    <NumericFormat
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={billing?.amount}
                      prefix={`${getCurrencyCode(billing?.currency ?? '') ?? ' '} `}
                    />
                  }
                </span>
              </div>
              <div className="item">
                <label>Bill Date</label>
                <span className="value">{dayjs(billing?.billDate).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Bill Period</label>
                <span className="value">{dayjs(billing?.billPeriod).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Created At</label>
                <span className="value">{dayjs(billing?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Created By</label>
                <span className="value">{billing?.createdBy ? getUsername(billing?.createdBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Updated At</label>
                <span className="value">
                  {billing?.updatedBy ? dayjs(billing?.updatedAt).format(DATE_FORMAT_FRONTEND) : '-'}
                </span>
              </div>
              <div className="item">
                <label>Updated By</label>
                <span className="value">{billing?.updatedBy ? getUsername(billing?.updatedBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Remark</label>
                <span className="value">{billing?.remark}</span>
              </div>
            </div>
            <div className="page-content-footer">
              {access.isCanUpdate && (
                <Link to={`/billings/${id}/edit`}>
                  <PrimaryButton label="Edit" icon={<EditIcon />} />
                </Link>
              )}
              {access.isCanDelete && <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />}
              <BackButton label="Cancel" icon={<CancelIcon />} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default BillingNewPage;
