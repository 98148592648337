import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import React from 'react';
import './DropdownCustom.scss';

type Props = {
  menu: MenuProps;
  label?: string;
  icon?: any;
  className?: 'rotate-menu' | 'primary-menu' | string | undefined;
  props?: any;
  placement?: string;
  overlayClassName?: string;
};

const DropdownCustom: React.FC<Props> = ({ menu, label, icon, className, props, placement, overlayClassName }) => {
  return (
    <Dropdown
      {...props}
      menu={menu}
      className={`dropdown-custom ${className}`}
      placement={placement || 'bottomLeft'}
      overlayClassName={overlayClassName}
    >
      <Button>
        <Space>
          {label}
          {icon ? icon : <DownOutlined />}
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DropdownCustom;
