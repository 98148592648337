import { Spin, message } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { doCreateFilm } from '../../api/films';
import { FilmI } from '../../interfaces/film';
import FilmForm from '../../shared/components/forms/film/Form';

const FilmNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading } = useMutation(doCreateFilm, {
    onSuccess: () => {
      message.info('Create film successfully!');
      queryClient.invalidateQueries({
        queryKey: ['films', 'list'],
      });
      navigate(`/films`, { replace: true });
    },
    onError: () => {
      message.error('Create film failed! Please try again');
    },
  });

  const handleSubmit = async (values: FilmI) => {
    createMutate(values);
  };

  useEffect(() => {
    localStorage.setItem('from', 'film-create');
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="film-new-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Add New Film</h2>
            </div>
            <FilmForm handleSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default FilmNewPage;
