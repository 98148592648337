import { message, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DangerButton from '../../shared/components/buttons/DangerButton';
import { DATE_FORMAT_FRONTEND } from '../../shared/constants';
import CancelIcon from '../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../shared/svgs/DeleteForeverIcon';
import BackButton from '../../shared/components/buttons/BackButton';
import './SalesheetDetails.scss';
import {
  doDeleteSalesheet,
  doDuplicateSalesheet,
  doExportSalesheet,
  doExportSalesheetPdf,
  useGetSalesheelDetails,
} from '../../api/salesheets';
import dayjs from 'dayjs';
import GetPassword from './components/GetPassword';
import DisplayLinkComponent from './components/DisplayLink';
import { FilmI } from '../../interfaces/film';
import FilmDetailsComponent from '../../shared/components/FilmDetails';
import ConfirmButton from '../../shared/components/buttons/ConfirmButton';
import CheckCircleIcon from '../../shared/svgs/CheckCircleIcon';
import FileCopyIcon from '../../shared/svgs/FilmCopyIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/global';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import DownloadIcon from '../../shared/svgs/DownloadIcon';

const SalesheetDetaisPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles || [], 'salesheets');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data: salesheet, isLoading } = useGetSalesheelDetails(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(doDeleteSalesheet, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['salesheets', 'list'],
      });
      navigate(`/salesheets`, { replace: true });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const { mutate: duplicateMutate } = useMutation(doDuplicateSalesheet, {
    onSuccess: async () => {
      message.info('Duplicate salesheet successfully!');
      queryClient.invalidateQueries({
        queryKey: ['salesheets', 'list'],
      });
      navigate(`/salesheets`, { replace: true });
    },
    onError: () => {
      message.error('Duplicate salesheet failed! Please try again');
    },
  });

  const onDuplicateClick = () => {
    duplicateMutate(Number(id));
  };

  const { mutate: exportMutate } = useMutation(doExportSalesheet, {
    onSuccess: async () => {
      message.info('Successfully!');
    },
    onError: () => {
      message.error('Something went wrong! Please try again');
    },
  });

  const { mutate: exportPdfMutate, isLoading: isDownloadingPdf } = useMutation(doExportSalesheetPdf, {
    onSuccess: async () => {
      message.info('Successfully!');
    },
    onError: () => {
      message.error('Something went wrong! Please try again');
    },
  });

  const handleExportSubmit = async () => {
    setExportLoading(true);
    let params: any = { ids: [] };
    params = {
      ids: [id],
    };
    exportMutate(params);
    setTimeout(() => {
      setExportLoading(false);
    }, 1000);
  };

  return (
    <Spin spinning={isLoading}>
      <div id="salesheets-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Salesheet Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Created By</label>
                <span className="value">
                  {salesheet?.createdBy.firstName} {salesheet?.createdBy.lastName}
                </span>
              </div>
              <div className="item">
                <label>Link</label>
                <span className="value">
                  <DisplayLinkComponent link={salesheet?.link} />
                </span>
              </div>
              <div className="item">
                <label>Password</label>
                <span className="value show-password-value">
                  <span>
                    <GetPassword salesheet={salesheet} />
                  </span>
                </span>
              </div>
              <div className="item">
                <label>Created At</label>
                <span className="value">{dayjs(salesheet?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
            </div>
            <div className="page-content-footer">
              {access.isCanCreate && (
                <ConfirmButton
                  label="Duplicate"
                  className="btn-primary"
                  content={'Are you sure to duplicate this salesheet?'}
                  onOk={onDuplicateClick}
                  icon={<FileCopyIcon />}
                  confirmIcon={<CheckCircleIcon color="#009B40" />}
                />
              )}
              {access.isCanDelete && <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />}
              <PrimaryButton
                label="Export"
                onClick={handleExportSubmit}
                loading={exportLoading}
                icon={<DownloadIcon />}
              />
              <PrimaryButton
                label="Export as PDF"
                onClick={() => {
                  exportPdfMutate(Number(id));
                }}
                loading={isDownloadingPdf}
                icon={<DownloadIcon />}
              />
              <BackButton label="Cancel" icon={<CancelIcon />} />
            </div>
          </div>
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Films</h2>
            </div>
            {salesheet?.salesSheetDetails &&
              salesheet.salesSheetDetails.map((film: FilmI, index: number) => (
                <FilmDetailsComponent key={index} film={film} hideActionButtons={false} hideCreatedAt={false} />
              ))}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SalesheetDetaisPage;
