import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../interfaces/user';
import { PASSWORD_TIP } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import { useQuery } from 'react-query';
import { doGetRoleList } from '../../../../api/auth';
import { humanizeText } from '../../../../utils/global';
import BackButton from '../../buttons/BackButton';

type Props = {
  isFetching?: boolean;
  userId?: number;
  user?: any;
  handleSubmit(_: any): void;
};

const UserForm: React.FC<Props> = ({ user, userId, isFetching, handleSubmit }) => {
  const [password, setPassword] = useState<any>({});
  const [form] = Form.useForm();
  const passwordTips = PASSWORD_TIP;
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');

  const {
    data: rolesData,
    isLoading: isRoleLoading,
    refetch: refetchRoles,
  } = useQuery(['rights', 'list'], () => doGetRoleList(), {
    refetchOnMount: true,
  });
  const isEdit = user != null;

  // onmount to refetch
  useEffect(() => {
    refetchRoles();
  }, []);

  function validatePassword(value: string) {
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const handleOnFinish = (values: User) => {
    const newValues: any = values;
    const passwordValue = values.password;

    if (passwordValue) {
      if (strongRegex.test(passwordValue)) {
        handleSubmit(newValues);
      }
    } else {
      handleSubmit(newValues);
    }
  };

  useEffect(() => {
    if (user && user.id === userId) {
      form.setFieldsValue({
        ...user,
        roleIds: user.roles.map((role: any) => role.id),
      });
    }
  }, [user]);

  return (
    <div className="user-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{ isActive: true }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Invalid Email' },
                { required: true, message: 'Required' },
              ]}
            >
              <Input type="email" placeholder="Required" autoComplete="off" disabled={isEdit} />
            </Form.Item>
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="Required" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item
              className="password-input"
              label="Password"
              name="password"
              validateStatus={password.validateStatus}
              help={password.errorMsg || passwordTips}
              rules={[{ required: !userId, message: 'Required' }]}
            >
              <Input
                type="password"
                placeholder={userId ? 'Optional' : 'Required'}
                onChange={onPasswordChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item label="Roles" name="roleIds" rules={[{ required: true, message: 'Required' }]}>
              <Select placeholder="Required" style={{ width: '100%' }} mode="multiple" loading={isRoleLoading}>
                {rolesData?.data?.map((role: any) => (
                  <Select.Option key={role.id} value={role.id}>
                    <span className="text-capitalize">{humanizeText(role.name)}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton
              loading={isFetching}
              label={userId ? 'Update' : 'Create'}
              icon={<CheckCircleIcon />}
              htmlType="submit"
            />
            <BackButton label={'Cancel'} icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
