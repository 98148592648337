import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  BillingDetailsPage,
  BillingNewPage,
  BillingsPage,
  BillingUpdatePage,
  CustomersPage,
  CustomerDetailsPage,
  CustomerNewPage,
  CustomerUpdatePage,
  E403Page,
  E404Page,
  FilmDetailsPage,
  FilmNewPage,
  FilmsPage,
  FilmUpdatePage,
  LicenseDetailsPage,
  LicenseNewPage,
  LicensesPage,
  LicenseUpdatePage,
  LoginPage,
  RightDetailsPage,
  RightNewPage,
  RightsPage,
  RightUpdatePage,
  SalesheetDetailsPage,
  SalesheetNewPage,
  SalesheetsPage,
  UserDetailsPage,
  UserNewPage,
  UsersPage,
  UserUpdatePage,
  SuppliersPage,
  SupplierNewPage,
  SupplierDetailsPage,
  SupplierUpdatePage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from './pages';
import SalesheetDetailsForCustomerPage from './pages/salesheet/SalesheetDetailsForCustomer';
import RequireAuth from './shared/components/RequireAuth';
import AuditLogsPage from './pages/auditLog';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/salesheet/:id" element={<SalesheetDetailsForCustomerPage />} />
      <Route path="/" element={<RequireAuth />}>
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/new" element={<UserNewPage />} />
        <Route path="/users/:id" element={<UserDetailsPage />} />
        <Route path="/users/:id/edit" element={<UserUpdatePage />} />
        <Route path="/rights" element={<RightsPage />} />
        <Route path="/rights/new" element={<RightNewPage />} />
        <Route path="/rights/:id" element={<RightDetailsPage />} />
        <Route path="/rights/:id/edit" element={<RightUpdatePage />} />
        <Route path="/films" element={<FilmsPage />} />
        <Route path="/films/new" element={<FilmNewPage />} />
        <Route path="/films/:id" element={<FilmDetailsPage />} />
        <Route path="/films/:id/edit" element={<FilmUpdatePage />} />
        <Route path="/licenses" element={<LicensesPage />} />
        <Route path="/licenses/new" element={<LicenseNewPage />} />
        <Route path="/licenses/:id" element={<LicenseDetailsPage />} />
        <Route path="/licenses/:id/edit" element={<LicenseUpdatePage />} />
        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/customers/new" element={<CustomerNewPage />} />
        <Route path="/customers/:id" element={<CustomerDetailsPage />} />
        <Route path="/customers/:id/edit" element={<CustomerUpdatePage />} />
        <Route path="/suppliers" element={<SuppliersPage />} />
        <Route path="/suppliers/new" element={<SupplierNewPage />} />
        <Route path="/suppliers/:id" element={<SupplierDetailsPage />} />
        <Route path="/suppliers/:id/edit" element={<SupplierUpdatePage />} />
        <Route path="/billings" element={<BillingsPage />} />
        <Route path="/billings/new" element={<BillingNewPage />} />
        <Route path="/billings/:id" element={<BillingDetailsPage />} />
        <Route path="/billings/:id/edit" element={<BillingUpdatePage />} />
        <Route path="/salesheets" element={<SalesheetsPage />} />
        <Route path="/salesheets/new" element={<SalesheetNewPage />} />
        <Route path="/salesheets/:id" element={<SalesheetDetailsPage />} />
        <Route path="/audits" element={<AuditLogsPage />} />
      </Route>
      <Route path="/*" element={<E404Page />} />
      <Route path="/unauthorized" element={<E403Page />} />
    </Routes>
  );
};

export default App;
