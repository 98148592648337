import { ForgetPasswordParamsI } from '../interfaces/auth';
import { api } from '../shared/api';
import { useFetch } from '../shared/reactQuery';

export const doLogin = (params: any) => api.post<{ data: any }>('login', params);

export const useGetProfile = () => {
  return useFetch<any>(`me`);
};

export const doGetProfile = () => api.get<{ data: any }>('me');

export const doForgotPassword = async (params: ForgetPasswordParamsI) => {
  const { data: response }: any = await api.post('forget-password', params);
  return response.data;
};

export const doResetPassword = async (params: { email: string }) => {
  const { data: response }: any = await api.post('reset-password', params);
  return response.data;
};

export const doGetRoleList = async () => {
  const { data: response }: any = await api.get(`roles`);
  return response;
};
