import React, { Fragment } from 'react';
import { FilmI } from '../../../interfaces/film';
import FilmDetailsComponent from '../../../shared/components/FilmDetails';
import { Header } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import '../../../global.scss';

type Props = {
  salesheet: FilmI[];
};

const CustomerSalesheet: React.FC<Props> = ({ salesheet }) => {
  return (
    <Fragment>
      <Header className="site-header">
        <Link to="/">
          <img src="/logo.png" alt="logo" />
        </Link>
      </Header>
      {Object.values(salesheet).map((film: FilmI) => (
        <FilmDetailsComponent key={film.salesheetId} film={film} hideActionButtons={false} hideCreatedAt={false} />
      ))}
    </Fragment>
  );
};

export default CustomerSalesheet;
