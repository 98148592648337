import { Form, Input } from 'antd';
import React from 'react';
import './EnterPassword.scss';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import CheckCircleIcon from '../../../shared/svgs/CheckCircleIcon';

type Props = {
  onEnterPasswordSubmit: (_: any) => void;
};

const EnterPasswordForm: React.FC<Props> = ({ onEnterPasswordSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onEnterPasswordSubmit(values);
  };

  return (
    <div className="enter-password-container">
      <div className="page-content">
        <div className="page-content-header">
          <h2 className="title-page">Enter Password</h2>
        </div>
        <Form onFinish={onFinish} form={form} requiredMark={false} colon={false} scrollToFirstError autoComplete="off">
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'required' }]}>
            <Input placeholder="Enter password" type="password" />
          </Form.Item>
          <div className="page-content-footer">
            <PrimaryButton label="Submit" htmlType="submit" icon={<CheckCircleIcon />} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EnterPasswordForm;
