import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FilmI } from '../../../interfaces/film';
import { DateFormat, getCurrencyCode } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';
import { CustomerI } from '../../../interfaces/customer';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const BillingsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Film',
      dataIndex: 'film',
      width: 150,
      render: (film: FilmI) => {
        return <span>{film?.title}</span>;
      },
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 150,
      render: (customer: CustomerI) => <span>{customer?.name}</span>,
    },
    {
      title: 'Amount',
      width: 100,
      render: (record: any) => {
        return (
          <NumericFormat
            displayType="text"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={record?.amount}
            prefix={`${getCurrencyCode(record?.currency ?? '') ?? ' '} `}
          />
        );
      },
    },
    {
      title: 'Bill Date',
      dataIndex: 'billDate',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'Bill Period',
      dataIndex: 'billPeriod',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: 100,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default BillingsTable;
