import React from 'react';
import { Button, message } from 'antd';
import { useQuery } from 'react-query';
import { doGetSalesheetPassword } from '../../../api/salesheets';
import { SalesheetI } from '../../../interfaces/salesheet';
import './GetPassword.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { replaceTextWithDots } from '../../../utils/global';

type Props = {
  salesheet?: SalesheetI;
};

const GetPassword: React.FC<Props> = ({ salesheet }) => {
  const { data, refetch } = useQuery(
    ['decrytedPassword', salesheet?.id],
    () => doGetSalesheetPassword(salesheet?.id || 0),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const onShowPasswordClick = () => {
    refetch();
  };

  const onCopyClick = () => {
    message.info('Coppied');
  };

  return (
    <div className="get-password-section">
      <span className="password-value">{data?.password || replaceTextWithDots(salesheet?.password || '', 7)}</span>
      <Button type="primary" onClick={onShowPasswordClick}>
        Show
      </Button>
      {data?.password && (
        <CopyToClipboard text={data?.password} onCopy={onCopyClick}>
          <Button style={{ marginLeft: 16 }}>Copy</Button>
        </CopyToClipboard>
      )}
    </div>
  );
};

export default GetPassword;
