import { PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getSuppliersList } from '../../../../api/suppliers';
import { GENRE_LIST } from '../../../../interfaces/film';
import { RightI } from '../../../../interfaces/right';
import { SupplierI } from '../../../../interfaces/supplier';
import {
  handleGetCountriesWithSEA,
  mergeUniqueStringArray,
  setStartTime,
  setEndTime,
  convertDateTimeToTimestamp,
  getCountriesList,
  getCountryLabel,
  replaceCountryName,
  getCountryValue,
} from '../../../../utils/global';
import { DATE_FORMAT_FRONTEND, getSEACountries, SEACountries } from '../../../constants';
import CancelIcon from '../../../svgs/CancelIcon';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import DeleteForeverIcon from '../../../svgs/DeleteForeverIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import ImageUploader from '../../uploads/Image';
import './Form.scss';
import dayjs from 'dayjs';
import { getRightsList } from '../../../../api/rights';
import BackButton from '../../buttons/BackButton';
import FileCopyIcon from '../../../svgs/FilmCopyIcon';
import SecondaryButton from '../../buttons/SecondaryButton';

const { RangePicker } = DatePicker;

type Props = {
  filmId?: number;
  film?: any;
  handleSubmit(_: any): void;
};

const FilmForm: React.FC<Props> = ({ film, filmId, handleSubmit }) => {
  const [form] = Form.useForm();
  const [panels, setPanels] = useState<any>([]);
  const [posters, setPosters] = useState<any>([]);
  const [selectedRegion, setSelectedRegion] = useState<{ [key: number]: string[] } | null>(null);
  const fixedCountryOptions = useMemo(() => getCountriesList(), []);
  const newCountryList = [{ value: 'SEA', label: 'SEA' }].concat(fixedCountryOptions);
  const countryWithWorldWide = [{ value: 'WORLDWIDE', label: 'WORLDWIDE' }].concat(newCountryList);

  const {
    data: rightsList,
    isFetching: isRightFetching,
    refetch: refetchRightsList,
  } = useQuery(['rights', 'list'], () => getRightsList({}), { refetchOnMount: true });

  const { data: suppliers, isFetching: isSupplierFetching } = useQuery(['suppliers', 'list'], () =>
    getSuppliersList({}),
  );

  const handleOnFinish = (values: any) => {
    const newValues: any = values;
    const licenseStartDate =
      values.licenseDateRange && values.licenseDateRange[0]
        ? convertDateTimeToTimestamp(setStartTime(values.licenseDateRange[0]))
        : null;
    const licenseEndDate =
      values.licenseDateRange && values.licenseDateRange[1]
        ? convertDateTimeToTimestamp(setEndTime(values.licenseDateRange[1]))
        : null;

    delete values.contractDateRange;
    delete values.licenseDateRange;

    newValues.licenseStartDate = licenseStartDate;
    newValues.licenseEndDate = licenseEndDate;
    newValues.panelImage = panels;
    newValues.posterImage = posters;

    if (values?.territories && Object.keys(values.territories).length > 0) {
      const newTerritories = values.territories.map((territory: any, key: number) => {
        const exclusiveRights = territory.exclusiveRights || [];
        const nonExclusiveRights = territory.nonExclusiveRights || [];
        const newExclusiveRights = exclusiveRights.map((right: any) => {
          if (right.rightId) {
            const startDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setStartTime(right.startEndDateRange[0]))
                : undefined;
            const endDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setEndTime(right.startEndDateRange[1]))
                : undefined;
            return {
              ...right,
              rightId: right.rightId,
              startDate,
              endDate,
            };
          } else return undefined;
        });
        const newNonExclusiveRights = nonExclusiveRights.map((right: any) => {
          if (right.rightId) {
            const startDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setStartTime(right.startEndDateRange[0]))
                : undefined;
            const endDate =
              right.startEndDateRange && Object.values(right.startEndDateRange).length > 0
                ? convertDateTimeToTimestamp(setEndTime(right.startEndDateRange[1]))
                : undefined;

            return {
              ...right,
              rightId: right.rightId,
              startDate,
              endDate,
            };
          } else return undefined;
        });
        delete territory.id;
        let newNames: string[] = [];
        if (territory.names) {
          let newCountryCodes: string[] = [];
          if (territory.names.includes('WORLDWIDE')) {
            newNames = ['worldwide'];
          } else {
            if (territory.names.includes('SEA') || territory.names.includes('sea')) {
              const sea = getSEACountries;
              const territoriesWithoutSEA = territory.names.filter((item: string) => item !== 'SEA' && item !== 'sea');
              newCountryCodes = mergeUniqueStringArray(sea, territoriesWithoutSEA);
            } else {
              newCountryCodes = territory.names;
            }
            newNames = newCountryCodes?.map((item: string) => getCountryLabel(newCountryList, item).toLowerCase());
          }
        }
        if (territory.name) delete territory.name;
        const excludedRegionNames = [
          ...new Set(
            (isWorldWide(key) &&
              territory.excludedTerritories &&
              territory.excludedTerritories.flatMap((countryCode: string) =>
                countryCode.toLowerCase() === 'sea'
                  ? SEACountries.map((country) => replaceCountryName(country.toLowerCase()))
                  : getCountryLabel(newCountryList, countryCode).toLowerCase(),
              )) ||
              [],
          ),
        ];
        if (territory.excludedTerritories) delete territory.excludedTerritories;
        return {
          ...territory,
          names: newNames,
          excludedTerritories: excludedRegionNames,
          nonExclusiveRights: newNonExclusiveRights.filter((item: any) => item !== undefined),
          exclusiveRights: newExclusiveRights.filter((item: any) => item !== undefined),
        };
      });
      newValues.territories = newTerritories;
      delete values.startEndDateRange;
    }
    const dateOfAgreement = values.dateOfAgreement ? convertDateTimeToTimestamp(values.dateOfAgreement) : null;
    const countryOfOrigin = values.countryOfOrigin
      ? getCountryLabel(countryWithWorldWide, values?.countryOfOrigin) || values.countryOfOrigin
      : null;
    newValues.dateOfAgreement = dateOfAgreement;
    newValues.countryOfOrigin = countryOfOrigin;
    newValues.coShareValue = values?.coShareValue;
    handleSubmit(newValues);
  };

  function mergeTerritories(territories: any) {
    return territories.map((territory: any, key: number) => {
      let countryCodes: string[] | undefined = undefined;
      if (territory?.name) {
        if (territory.name.includes('worldwide') || territory.name.includes('WORLDWIDE')) {
          countryCodes = ['WORLDWIDE'];
          setSelectedRegion((state) => {
            if (state == null) state = {};
            return { ...state, [key]: ['WORLDWIDE'] };
          });
        } else {
          const newCountries = handleGetCountriesWithSEA(territory.name);
          countryCodes = newCountries.map((item: string) => getCountryValue(newCountryList, item) || item);
          setSelectedRegion((state) => {
            if (countryCodes == null) return state;
            if (state == null) state = {};
            return { ...state, [key]: countryCodes };
          });
        }
      }
      const excludedTerritories =
        (territory?.excludedTerritories && handleGetCountriesWithSEA(territory?.excludedTerritories)) || [];
      const excludedTerritoriesCode: string[] =
        excludedTerritories.map((country: string) =>
          country.toLowerCase() === 'sea' ? 'SEA' : getCountryValue(newCountryList, country),
        ) || [];
      const exclusiveRights = territory.exclusiveRights.map((right: RightI) => {
        const startDate = right.startDate;
        const endDate = right.endDate;
        return {
          rightId: right.rightId,
          startEndDateRange: !startDate && !endDate ? [] : [dayjs(right.startDate), dayjs(right.endDate)],
          remarks: right.remarks,
        };
      });

      const nonExclusiveRights = territory.nonExclusiveRights.map((right: RightI) => {
        const startDate = right.startDate;
        const endDate = right.endDate;
        return {
          rightId: right.rightId,
          startEndDateRange: !startDate && !endDate ? [] : [dayjs(right.startDate), dayjs(right.endDate)],
          remarks: right.remarks,
        };
      });
      return {
        ...territory,
        names: countryCodes,
        excludedTerritories: excludedTerritoriesCode,
        exclusiveRights,
        nonExclusiveRights,
      };
    });
  }

  const onDuplicateClick = () => {
    const territories = form.getFieldsValue().territories;
    const duplicateData = [...territories, ...territories];
    setSelectedRegion((state) => {
      // nothing to duplicate
      if (state == null) return state;
      const duplicatedSelectedRights = Object.keys(state).reduce(
        (
          accumulator: {
            [key: number]: string[];
          },
          currentValue,
          index,
        ) => {
          const newKey = Object.keys(state).length + index;
          accumulator[newKey] = state[parseInt(currentValue)];
          return accumulator;
        },
        {},
      );
      return { ...state, ...duplicatedSelectedRights };
    });
    form.setFieldsValue({ territories: duplicateData });
  };

  function disabledDate(current: dayjs.Dayjs) {
    const dateRange = form.getFieldValue('licenseDateRange');
    if (dateRange == null) return true;
    const [startDate, endDate] = dateRange;
    return current && (current.isBefore(setStartTime(startDate)) || current.isAfter(setEndTime(endDate)));
  }

  function handleRegionChange(key: number, selected: string[]) {
    setSelectedRegion((state) => {
      if (state == null) state = {};
      return {
        ...state,
        [key]: selected,
      };
    });
  }

  function isWorldWide(key: number) {
    if (selectedRegion == null || selectedRegion[key] == null) return false;
    return selectedRegion[key].some((region) => region === 'WORLDWIDE');
  }

  useEffect(() => {
    if (film && film.id === filmId) {
      const territories: any[] = film?.territories;
      const mergedTerritories = mergeTerritories(territories);
      form.setFieldsValue({
        ...film,
        supplierId: film.supplier?.id,
        licenseDateRange:
          film?.licenseStartDate && film?.licenseEndDate
            ? [dayjs(film.licenseStartDate), dayjs(film.licenseEndDate)]
            : null,
        territories: mergedTerritories,
        panelImage: { fileList: film.panelImage },
        posterImage: { fileList: film.posterImage },
        dateOfAgreement: film?.dateOfAgreement && dayjs(film?.dateOfAgreement),
        genre: film?.genre ? film.genre : [],
        coShareValue: film?.coShareValue ? film?.coShareValue : '',
      });
      setPanels(
        film.panelImage?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
      setPosters(
        film.posterImage?.map((d: any) => {
          return { ...d, url: d.s3Url };
        }),
      );
    }
  }, [film]);

  useEffect(() => {
    refetchRightsList();
  }, []);

  return (
    <div className="film-form-container">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        autoComplete="off"
        initialValues={{
          territories: [
            {
              exclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
              nonExclusiveRights: [{ id: null, startEndDateRange: [], remarks: null }],
            },
          ],
        }}
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="Required" disabled={!!filmId} />
            </Form.Item>
            <Form.Item label="Title Chinese" name="titleCN">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Supplier" name="supplierId">
              <Select placeholder="Optional" showSearch optionFilterProp="children" loading={isSupplierFetching}>
                {suppliers?.data?.map((item: SupplierI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="License Fee" name="licenseFee">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Co ShareName" name="coShareName">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Co Share Value" name="coShareValue">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Date Of Agreement" name="dateOfAgreement">
              <DatePicker className="ant" placeholder="Optional" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item name="licenseDateRange" label="Overall License Period">
              <RangePicker className="ant" format={DATE_FORMAT_FRONTEND} />
            </Form.Item>
            <Form.Item label="Genre" name="genre">
              <Select
                placeholder="Optional"
                showSearch
                optionFilterProp="children"
                loading={isSupplierFetching}
                mode="multiple"
              >
                {GENRE_LIST.map((item: any) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Director" name="director">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Cast" name="cast">
              <Input.TextArea placeholder="Optional" rows={3} />
            </Form.Item>
            <Form.Item label="Box Office" name="boxOffice">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Year" name="year">
              <InputNumber placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Synopsis" name="synopsis">
              <Input.TextArea placeholder="Optional" rows={3} />
            </Form.Item>
            <Form.Item label="Languages" name="languages">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Trailer" name="trailer">
              <Input placeholder="Optional" />
            </Form.Item>
            <Form.Item label="Country of Origin" name="countryOfOrigin">
              <Select placeholder="Optional" showSearch optionFilterProp="label" options={fixedCountryOptions} />
            </Form.Item>
            <Form.Item label="Contract Reference Number" name="contractReferenceNumber">
              <Input placeholder="Optional" />
            </Form.Item>
            {/* <Form.Item label="Panel Image">
              <ImageUploader
                name="panelImage"
                documents={panels}
                setDocuments={setPanels}
                multiple={false}
                maxCount={2}
                acceptType="image/png, image/jpeg"
              />
            </Form.Item> */}
            <Form.Item label="Poster Image">
              <ImageUploader
                name="posterImage"
                documents={posters}
                setDocuments={setPosters}
                multiple={false}
                maxCount={2}
                acceptType="image/png, image/jpeg"
              />
            </Form.Item>
            <div className="page-content-header">
              <h2 className="title-page">Add Territories</h2>
              <div>
                <SecondaryButton label="Duplicate" onClick={onDuplicateClick} icon={<FileCopyIcon />} />
              </div>
            </div>
            <Form.List name="territories">
              {(fields, { add, remove }) => {
                return (
                  <div className="territory-list-section">
                    {fields.map(({ key, name, ...restField }) => (
                      <Fragment key={key}>
                        <div>
                          <Form.Item {...restField} label="Region" name={[name, 'names']}>
                            <Select
                              placeholder="Optional"
                              showSearch
                              optionFilterProp="label"
                              loading={isRightFetching}
                              options={countryWithWorldWide}
                              mode="multiple"
                              onChange={(selected) => handleRegionChange(key, selected)}
                              allowClear
                            />
                          </Form.Item>
                          {isWorldWide(key) && (
                            <Form.Item {...restField} label="Excluded Region" name={[name, 'excludedTerritories']}>
                              <Select
                                placeholder="Optional"
                                showSearch
                                optionFilterProp="label"
                                loading={isRightFetching}
                                options={newCountryList}
                                mode="multiple"
                                allowClear
                              />
                            </Form.Item>
                          )}
                          <Form.Item {...restField} label="Remarks" name={[name, 'remarks']}>
                            <Input.TextArea placeholder="Optional" rows={3} />
                          </Form.Item>
                          <div className="rights-list-section">
                            <h2 className="subtitle-page">Exclusive Rights</h2>
                            <Form.List name={[name, 'exclusiveRights']}>
                              {(exclusiveRights, { add, remove }) => {
                                return (
                                  <div className="right-item-section">
                                    {exclusiveRights.map(({ key: eKey, name: eName, ...eRestField }) => (
                                      <Fragment key={eKey}>
                                        <div>
                                          <Form.Item {...eRestField} label="Right" name={[eName, 'rightId']}>
                                            <Select
                                              placeholder="Optional"
                                              showSearch
                                              optionFilterProp="children"
                                              loading={isRightFetching}
                                            >
                                              {rightsList?.data?.map((item: RightI, rightIdx: number) => (
                                                <Select.Option key={rightIdx} value={item.id}>
                                                  {item?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                          <Form.Item
                                            {...eRestField}
                                            label="Start - End Date"
                                            name={[eName, 'startEndDateRange']}
                                          >
                                            <RangePicker
                                              className="ant"
                                              format={DATE_FORMAT_FRONTEND}
                                              disabledDate={disabledDate}
                                            />
                                          </Form.Item>
                                          <Form.Item {...eRestField} label="Remarks" name={[eName, 'remarks']}>
                                            <Input.TextArea placeholder="Optional" rows={3} />
                                          </Form.Item>
                                        </div>
                                        {exclusiveRights.length >= 1 && (
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              remove(eName);
                                            }}
                                            block
                                            className="btn-remove"
                                          >
                                            <DeleteForeverIcon width={16} height={16} /> Remove Exclusive Right
                                          </Button>
                                        )}
                                      </Fragment>
                                    ))}

                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          add({ startEndDateRange: [], id: '', remarks: '' });
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Exclusive Right
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                            <h2 className="subtitle-page">Non Exclusive Rights</h2>
                            <Form.List name={[name, 'nonExclusiveRights']}>
                              {(nonExclusiveRights, { add, remove }) => {
                                return (
                                  <div className="right-item-section">
                                    {nonExclusiveRights.map(({ key: nKey, name: nName, ...nRestField }) => (
                                      <Fragment key={nKey}>
                                        <div>
                                          <Form.Item {...nRestField} label="Right" name={[nName, 'rightId']}>
                                            <Select
                                              placeholder="Optional"
                                              showSearch
                                              optionFilterProp="children"
                                              loading={isRightFetching}
                                            >
                                              {rightsList?.data?.map((item: RightI, rightIdx: number) => (
                                                <Select.Option key={rightIdx} value={item.id}>
                                                  {item?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                          <Form.Item
                                            {...nRestField}
                                            label="Start - End Date"
                                            name={[nName, 'startEndDateRange']}
                                          >
                                            <RangePicker
                                              className="ant"
                                              format={DATE_FORMAT_FRONTEND}
                                              disabledDate={disabledDate}
                                            />
                                          </Form.Item>
                                          <Form.Item {...nRestField} label="Remarks" name={[nName, 'remarks']}>
                                            <Input.TextArea placeholder="Optional" rows={3} />
                                          </Form.Item>
                                        </div>
                                        {nonExclusiveRights.length >= 1 && (
                                          <Button
                                            type="dashed"
                                            onClick={() => {
                                              remove(nName);
                                            }}
                                            block
                                            className="btn-remove"
                                          >
                                            <DeleteForeverIcon width={16} height={16} /> Remove Non Exclusive Right
                                          </Button>
                                        )}
                                      </Fragment>
                                    ))}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          add({ startEndDateRange: [], id: '', remarks: '' });
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Non Exclusive Right
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </div>
                        </div>
                        {fields.length >= 1 && (
                          <Button
                            type="dashed"
                            onClick={() => {
                              remove(name);
                            }}
                            block
                            className="btn-remove"
                          >
                            <DeleteForeverIcon width={16} height={16} /> Remove Territory
                          </Button>
                        )}
                      </Fragment>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add({
                            name: '',
                            exclusiveRights: [{ id: '', startEndDateRange: [], remarks: '' }],
                            nonExclusiveRights: [{ id: '', startEndDateRange: [], remarks: '' }],
                          });
                        }}
                        block
                      >
                        <PlusOutlined /> Add Territory
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
          <div className="form-footer">
            <PrimaryButton label={filmId ? 'Update' : 'Create'} icon={<CheckCircleIcon />} htmlType="submit" />
            <BackButton label="Cancel" icon={<CancelIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FilmForm;
