import { message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateLicense, useGetLicenseDetails } from '../../api/licenses';
import LicenseForm from '../../shared/components/forms/license/Form';

const LicenseUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data: license, isLoading } = useGetLicenseDetails(Number(id));

  const { mutate: updateMutate } = useMutation(doUpdateLicense, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['licenses', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`licenses/${id}`, undefined],
      });
      message.info('Update license successfully!');
      navigate(`/licenses`, { replace: true });
    },
    onError: () => {
      message.error('Update license failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  useEffect(() => {
    localStorage.setItem('from', 'license-update');
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="license-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit License Sold</h2>
            </div>
            <LicenseForm handleSubmit={handleSubmit} license={license} licenseId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default LicenseUpdatePage;
