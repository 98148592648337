import { message, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { doDeleteLicense, useGetLicenseDetails } from '../../api/licenses';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import { DATE_FORMAT_FRONTEND } from '../../shared/constants';
import CancelIcon from '../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../shared/svgs/EditIcon';
import { TerritoryI } from '../../interfaces/territory';
import { RightI } from '../../interfaces/right';
import { CurrentUserI } from '../../interfaces/auth';
import { getUsername, handleAccessControl, handleGetCountriesWithSEA } from '../../utils/global';
import BackButton from '../../shared/components/buttons/BackButton';
import { LicenseI } from '../../interfaces/license';
// import { Tooltip } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';

const LicenseNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles, 'licenses');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: license, isLoading } = useGetLicenseDetails(Number(id));
  const { mutateAsync: mutationDelete } = useMutation(doDeleteLicense, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['licenses', 'list'],
      });
      navigate(`/licenses`, { replace: true });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  const [newLicenseState, setNewLicenseState] = useState<LicenseI>();
  useEffect(() => {
    if (license) {
      const newLicense = license;
      if (license.licenseTerritories) {
        const newTerritories = license.licenseTerritories.map((territory: any) => {
          const newCountries = handleGetCountriesWithSEA(territory.name);
          return {
            ...territory,
            name: newCountries,
          };
        });
        newLicense.licenseTerritories = newTerritories;
      }
      setNewLicenseState(newLicense);
      localStorage.setItem('from', 'license-detail');
    }
  }, [license]);
  return (
    <Spin spinning={isLoading}>
      <div id="licenses-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">License Sold Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Film</label>
                <span className="value">{newLicenseState?.film?.title}</span>
              </div>
              <div className="item">
                <label>Customer</label>
                <span className="value">{newLicenseState?.customer?.name}</span>
              </div>
              <div className="item">
                <label>Start - End Date</label>
                <span className="value">
                  {dayjs(newLicenseState?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                  {dayjs(newLicenseState?.endDate).format(DATE_FORMAT_FRONTEND)}
                </span>
              </div>
              <div className="item">
                <label>Contract Reference Number</label>
                <span className="value">{newLicenseState?.contractReferenceNumber}</span>
              </div>
              <div className="item">
                <label>Remark</label>
                <span className="value">{newLicenseState?.remark}</span>
              </div>
              <div className="item">
                <label>Territories</label>
                <span className="value">
                  <ul>
                    {newLicenseState?.licenseTerritories?.map((territory: TerritoryI) => (
                      <li key={territory.id}>
                        <h2 style={{ fontSize: 14, marginBottom: 5 }}>
                          {territory.name && territory.name.join(', ')}{' '}
                          {territory?.isWorldwide && (
                            // <Tooltip
                            //   placement="top"
                            //   title={
                            //     <>
                            //       <div>Excluded Regions</div>
                            //       <div>{handleGetCountriesWithSEA(territory.excludedTerritories).join(', ')}</div>
                            //     </>
                            //   }
                            //   key={index}
                            // >
                            //   <InfoCircleOutlined />
                            // </Tooltip>

                            <div>
                              Excluded Regions (
                              <span style={{ color: 'blue', wordWrap: 'break-word' }}>
                                {handleGetCountriesWithSEA(territory.excludedTerritories).join(', ')}
                              </span>
                              )
                            </div>
                          )}
                        </h2>
                        <div>
                          {territory?.exclusiveRights && Object.keys(territory?.exclusiveRights).length > 0 && (
                            <div className="item2">
                              <label>Exclusive Rights</label>
                              <ul>
                                {territory?.exclusiveRights?.map((right: RightI) => (
                                  <li style={{ marginBottom: 5 }} key={right.id}>
                                    {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                                    {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}{' '}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {territory?.nonExclusiveRights && Object.keys(territory?.nonExclusiveRights).length > 0 && (
                            <div className="item2">
                              <label>Non Exclusive Rights</label>
                              <ul>
                                {territory?.nonExclusiveRights?.map((right: RightI) => (
                                  <li style={{ marginBottom: 5 }} key={right.id}>
                                    {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                                    {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}{' '}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="item">
                <label>Created At</label>
                <span className="value">{dayjs(newLicenseState?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Created By</label>
                <span className="value">
                  {newLicenseState?.createdBy ? getUsername(newLicenseState?.createdBy) : '-'}
                </span>
              </div>
              <div className="item">
                <label>Updated At</label>
                <span className="value">
                  {newLicenseState?.updatedBy ? dayjs(newLicenseState?.updatedAt).format(DATE_FORMAT_FRONTEND) : '-'}
                </span>
              </div>
              <div className="item">
                <label>Updated By</label>
                <span className="value">
                  {newLicenseState?.updatedBy ? getUsername(newLicenseState?.updatedBy) : '-'}
                </span>
              </div>
            </div>
            <div className="page-content-footer">
              {access.isCanUpdate && (
                <Link to={`/licenses/${id}/edit`}>
                  <PrimaryButton label="Edit" icon={<EditIcon />} />
                </Link>
              )}
              {access.isCanDelete && <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />}
              <BackButton label="Cancel" icon={<CancelIcon />} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default LicenseNewPage;
