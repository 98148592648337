import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { doCreateRight } from '../../api/rights';
import { RightI } from '../../interfaces/right';
import RightForm from '../../shared/components/forms/right/Form';

const RightNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(doCreateRight, {
    onSuccess: async () => {
      message.info('Create right successfully!');
      queryClient.invalidateQueries({
        queryKey: ['rights', 'list'],
      });
      navigate(`/rights`, { replace: true });
    },
    onError: () => {
      message.error('Create right failed! Please try again');
    },
  });

  const handleSubmit = async (values: RightI) => {
    createMutate(values);
  };

  return (
    <div className="right-new-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Right</h2>
          </div>
          <RightForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default RightNewPage;
