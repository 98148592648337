import { Form, message, Upload } from 'antd';
import React from 'react';

type Props = {
  name: string;
  multiple?: boolean;
  documents: any[];
  setDocuments: (_: any) => void;
  isRequired?: boolean;
  isShowUploadList?: boolean;
  maxCount?: number;
  isDisabled?: boolean;
};

const ImportXLSX: React.FC<Props> = ({
  name,
  multiple,
  documents,
  maxCount,
  setDocuments,
  isRequired,
  isShowUploadList,
  isDisabled,
}) => {
  const handleFailed = (file: any) => message.error(`${file.name} file upload failed.`);
  const handleRemoved = (file: any) => message.success(`${file.name} file removed successful`);
  const handleUploaded = (file: any) => message.success(`${file.name} file uploaded successful.`);
  let counterFile = 0;
  const disable = isDisabled || false;
  const defaultMaxCount = maxCount ? maxCount : 1;

  const filesData: any[] = [];
  const handleChangeFile = async (file: any) => {
    if (file.status === undefined) {
      filesData.push(file);
      if (file) {
        setDocuments([file]);
        handleUploaded(file);
      } else {
        handleFailed(file);
      }
      counterFile = 0;
    }

    if (file.status === 'removed') {
      if (multiple) {
        const indexFile = documents.findIndex((f: any) => f.id === file.id);
        const files = [...documents];
        files.splice(indexFile, 1);

        setDocuments(files);
      } else {
        setDocuments([]);
      }
      handleRemoved(file);
    }

    if (file.status === 'error') {
      handleFailed(file);
    }
  };
  const uploadProps = {
    name,
    multiple: !!multiple,
    fileList: documents,
    accept: '.xlsx',
    beforeUpload: () => {
      ++counterFile;
      if (documents.length + counterFile > defaultMaxCount) {
        message.error(`Cannot upload more than ${defaultMaxCount} files`);
        return true;
      }
      return false;
    },
    async onChange({ file }: any) {
      handleChangeFile(file);
    },
    showUploadList: isShowUploadList && {
      showRemoveIcon: true,
    },
  };

  return (
    <Form.Item
      name={name}
      className="documents"
      rules={[
        { required: isRequired, message: 'Required' },
        {
          validator: (_, value) => {
            if (!isRequired || !value || value.fileList.length > 0) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Required'));
          },
        },
      ]}
    >
      <Upload.Dragger maxCount={defaultMaxCount} {...uploadProps} disabled={disable}>
        <div className="upload-text-section">
          <p className="ant-upload-text">Drag and drop to upload or</p>
          <div className="ant-upload-hint">Browse</div>
        </div>
      </Upload.Dragger>
    </Form.Item>
  );
};

export default ImportXLSX;
