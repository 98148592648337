import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import ClearIcon from '../../../svgs/ClearIcon';
import SearchIcon from '../../../svgs/SearchIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type Props = {
  queryParams: any;
  handleFilter(_: any): void;
  handleReset(): void;
};

const BillingsFilterForm: React.FC<Props> = ({ queryParams, handleFilter, handleReset }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: any) => {
    handleFilter(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
  };

  useEffect(() => {
    form.setFieldsValue({ title: queryParams.filter.title });
  }, [queryParams]);

  return (
    <div className="billings-filter-form-container filter-form-container">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleOnFinish} scrollToFirstError>
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="title">
              <Input placeholder="Title" />
            </Form.Item>
          </div>
          <div className="form-footer">
            <PrimaryButton label={'Search'} htmlType="submit" icon={<SearchIcon />} />
            <SecondaryButton label={'Reset'} onClick={handleResetForm} icon={<ClearIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BillingsFilterForm;
