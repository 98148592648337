import { GetListResponse } from '../interfaces';
import { useFetch } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { NotificationI } from '../interfaces/notification';

export const useGetNotificationsList = () => useFetch<GetListResponse<NotificationI>>('notifications');

export const useGetNotificationDetails = (id: number) => useFetch<NotificationI>(`notifications/${id}`);

export const doGetNotificationsList = async ({
  start,
  count,
  filter,
}: {
  start?: number;
  count?: number;
  filter?: any;
}) => {
  const newParams = handleFilterParams({
    start,
    count,
    filter,
  });
  const { data: response }: Partial<GetListResponse<NotificationI>> = await api.get(`notifications?${newParams}`);
  return response;
};

export const doUpdateNotification = async (id: number) => {
  const { data: response }: any = await api.patch(`notifications/${id}/isRead`, {});
  return response.data;
};

export const doDeleteNotification = async (id: number) => {
  const { data: response }: any = await api.delete(`notifications/${id}`);
  return response.data;
};

export const doDoneNotification = async (id: number) => {
  const { data: response }: any = await api.delete(`notifications/${id}/isDone`);
  return response.data;
};

export const doDeleteMultipleNotifications = async (ids: number[]) => {
  const { data: response }: any = await api.delete(`notifications/bulkDelete?ids=${ids}`);
  return response.data;
};
