import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { doUpdateCustomer, useGetCustomerDetails } from '../../api/customers';
import CustomerForm from '../../shared/components/forms/customer/Form';

const CustomerUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetCustomerDetails(Number(id));

  const { mutateAsync: updateMutate } = useMutation(doUpdateCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customers', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`customers/${id}`, undefined],
      });
      message.info('Update customer successfully!');
      navigate(`/customers`, { replace: true });
    },
    onError: () => {
      message.error('Update customer failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="company-update-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Edit Customer</h2>
            </div>
            <CustomerForm handleSubmit={handleSubmit} customer={data} customerId={Number(id)} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CustomerUpdatePage;
