import React from 'react';
import { DateFormat } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';
import { CustomerI } from '../../../interfaces/customer';
import { TerritoryI } from '../../../interfaces/territory';
import dayjs from 'dayjs';
import { RightI } from '../../../interfaces/right';
import { DATE_FORMAT_FRONTEND } from '../../constants';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const SoldLicensesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 150,
      render: (customer: CustomerI) => {
        return <span>{customer?.name}</span>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'Contract Reference Number',
      dataIndex: 'contractReferenceNumber',
      width: 150,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: 'Territories',
      dataIndex: 'licenseTerritories',
      render: (territories: TerritoryI[]) => {
        return (
          <ul>
            {territories?.map((territory: any) => (
              <li key={territory.id}>
                <h2 style={{ fontSize: 14, marginBottom: 5 }}>{territory.name}</h2>
                {territory.isWorldwide && territory.excludedTerritories && (
                  <span style={{ fontWeight: 'bold' }}>
                    Excluded Regions <span style={{ color: 'blue' }}>({territory.excludedTerritories})</span>
                  </span>
                )}
                <div>
                  {territory?.exclusiveRights && Object.keys(territory?.exclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Exclusive Rights</label>
                      <ul>
                        {territory?.exclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {territory?.nonExclusiveRights && Object.keys(territory?.nonExclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Non Exclusive Rights</label>
                      <ul>
                        {territory?.nonExclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default SoldLicensesTable;
