import React from 'react';
import { FilmI } from '../../../interfaces/film';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import './List.scss';
import { DateFormat, handleGetCoShareValue } from '../../../utils/global';
import DropdownCustom from '../../../shared/components/dropdowns/DropdownCustom';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import DangerButton from '../../../shared/components/buttons/DangerButton';
import { AccessControlI } from '../../../interfaces';
import { CurrentUserI } from '../../../interfaces/auth';

type Props = {
  films?: FilmI[];
  handleClickDelete: (_: any) => void;
  access: AccessControlI;
  currentUser?: CurrentUserI;
};

const FilmsList: React.FC<Props> = ({ films, handleClickDelete, access, currentUser }) => {
  const navigate = useNavigate();

  const onMoreDetailsClick = (id: number) => {
    navigate(`/films/${id}`);
  };

  const RenderDropDown = (record: any) => {
    return (
      <DropdownCustom
        placement="bottomRight"
        overlayClassName="table-action-menu"
        menu={{
          items: [
            access.isCanRead
              ? {
                  key: '3',
                  label: (
                    <Link to={`/films/${record?.id}`}>
                      <Button className="btn-table-more-details" type="link">
                        More Details
                      </Button>
                    </Link>
                  ),
                }
              : null,
            access.isCanUpdate
              ? {
                  key: '1',
                  label: (
                    <Link to={`/films/${record?.id}/edit`}>
                      <Button className="btn-table-action-primary" type="link">
                        Edit
                      </Button>
                    </Link>
                  ),
                }
              : null,
            access.isCanDelete
              ? {
                  key: '2',
                  label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
                }
              : null,
          ],
        }}
      />
    );
  };

  return (
    <div className="films-components-list-container">
      {films?.map((film: FilmI) => (
        <div className="page-content" key={film.id}>
          <div className="page-content-header">
            <h2 className="text-uppercase">{film.title}</h2>
            <div className="page-content-header-actions">
              {access.isCanRead && (
                <SecondaryButton
                  className="more-details-section"
                  label="More Details"
                  onClick={() => onMoreDetailsClick(film.id)}
                />
              )}
              <RenderDropDown {...film} />
            </div>
          </div>
          <div className="details-container">
            <div className="flex-2-item">
              {film.titleCN && (
                <div className="flex-item">
                  <label>Chinese Title</label>
                  <span className="value text-uppercase">{film.titleCN}</span>
                </div>
              )}
              <div className="flex-item">
                <label>License Period</label>
                <span className="value">
                  {DateFormat(film?.licenseStartDate)} - {DateFormat(film?.licenseEndDate)}
                </span>
              </div>
              {!film.titleCN && (
                <div className="flex-item">
                  <label>Co Share</label>
                  <span className="value">{handleGetCoShareValue(film, currentUser).join(', ')}</span>
                </div>
              )}
            </div>
            <div className="flex-2-item">
              {film.titleCN && (
                <div className="flex-item">
                  <label>Co Share</label>
                  <span className="value">{handleGetCoShareValue(film, currentUser).join(', ')}</span>
                </div>
              )}
              <div className="flex-item">
                <label>Supplier</label>
                <span className="value">{film?.supplier?.name}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilmsList;
