import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SecondaryButton.scss';

type Props = {
  label?: string;
  icon?: any;
  loading?: boolean;
  className?: string;
};

const BackButton: React.FC<Props> = ({ label, icon, loading, className }) => {
  const navigate = useNavigate();
  return (
    <Button type="default" className={`btn-secondary ${className}`} loading={loading} onClick={() => navigate(-1)}>
      {label}
      {icon}
    </Button>
  );
};

export default BackButton;
