import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { FilmI } from '../../../interfaces/film';
import { DateFormat, handleGetCoShareValue } from '../../../utils/global';
import { RightI } from '../../../interfaces/right';
import './Films.scss';
import { SupplierI } from '../../../interfaces/supplier';
import dayjs from 'dayjs';
import { DATE_FORMAT_FRONTEND } from '../../constants';
import { CurrentUserI } from '../../../interfaces/auth';
import { LicenseI } from '../../../interfaces/license';
import { TerritoryI } from '../../../interfaces/territory';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
  currentUser?: CurrentUserI;
};

const FilmsTable: React.FC<Props> = ({ dataSource, withAction, currentUser, ...props }) => {
  const COLUMNS = [
    {
      title: 'Title',
      width: 150,
      render: (film: FilmI) => {
        return (
          <span className="text-uppercase">
            {film.title} {film.titleCN && `《 ${film.titleCN} 》`}{' '}
          </span>
        );
      },
    },
    {
      title: 'License Period',
      width: 200,
      render: (film: any) => {
        return (
          <span className="value">
            {DateFormat(film?.licenseStartDate)} - {DateFormat(film?.licenseEndDate)}
          </span>
        );
      },
    },
    {
      title: 'Co Share',
      width: 100,
      render: (film: any) => {
        return <span>{handleGetCoShareValue(film, currentUser).join(', ')}</span>;
      },
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      width: 100,
      render: (supplier: SupplierI) => {
        return <span>{supplier?.name}</span>;
      },
    },
    {
      title: 'Films Rights',
      dataIndex: 'territories',
      width: 300,
      render: (territories: TerritoryI[]) => {
        return (
          <ul>
            {territories?.map((territory: TerritoryI) => (
              <li key={territory.id}>
                <h2 style={{ fontSize: 14, marginBottom: 5 }}>{territory.name ? territory.name.join(', ') : ''}</h2>
                <div>
                  {territory?.exclusiveRights && Object.keys(territory?.exclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Exclusive Rights</label>
                      <ul>
                        {territory?.exclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {territory?.nonExclusiveRights && Object.keys(territory?.nonExclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Non Exclusive Rights</label>
                      <ul>
                        {territory?.nonExclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      title: 'Licenses Sold',
      dataIndex: 'soldLicenses',
      width: 300,
      render: (soldLicenses: LicenseI[]) => {
        return soldLicenses?.map((license: LicenseI) => (
          <ul key={license?.id}>
            {license?.licenseTerritories?.map((territory: TerritoryI) => (
              <li key={territory.id}>
                <h2 style={{ fontSize: 14, marginBottom: 5 }}>{territory.name}</h2>
                <div>
                  {territory?.exclusiveRights && Object.keys(territory?.exclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Exclusive Rights</label>
                      <ul>
                        {territory?.exclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {territory?.nonExclusiveRights && Object.keys(territory?.nonExclusiveRights).length > 0 && (
                    <div className="item2">
                      <label>Non Exclusive Rights</label>
                      <ul>
                        {territory?.nonExclusiveRights?.map((right: RightI) => (
                          <li style={{ marginBottom: 5 }} key={right.id}>
                            {right.name}: {dayjs(right?.startDate).format(DATE_FORMAT_FRONTEND)} -{' '}
                            {dayjs(right?.endDate).format(DATE_FORMAT_FRONTEND)}
                            {right.remarks && ` Remarks: ${right.remarks}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ));
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default FilmsTable;
