import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PrimaryButton from '../buttons/PrimaryButton';
import LoginSVG from '../../svgs/logo.svg';
import './LoginForm.scss';
import { PASSWORD_TIP } from '../../constants';
import { ResetPasswordParamsI } from '../../../interfaces/auth';

type Props = {
  handleSubmit: (_: any) => void;
};

const ResetPasswordForm: React.FC<Props> = ({ handleSubmit }) => {
  const [password, setPassword] = useState<any>({});
  const [confirmPassword, setConfirmPassword] = useState<any>({});
  const passwordTips = PASSWORD_TIP;
  function validatePassword(value: string) {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (!value || strongRegex.test(value)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    if (confirmPassword && Object.keys(confirmPassword).length > 0 && confirmPassword.value !== '') {
      if (value === confirmPassword.value) {
        console.log('!strongRegex.test(value', !strongRegex.test(value));
        setConfirmPassword({
          ...confirmPassword,
          validateStatus: 'success',
          errorMsg: null,
        });

        if (!strongRegex.test(value)) {
          return {
            validateStatus: 'error',
            errorMsg: 'Your password too weak',
          };
        } else {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
      } else {
        return {
          validateStatus: 'error',
          errorMsg: 'The confirmed password and the password do not match',
        };
      }
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Your password too weak',
    };
  }

  function validateConfirmPassword(value: string) {
    if (value === '') {
      return {
        validateStatus: 'error',
        errorMsg: 'Required',
      };
    }

    if (value === password.value) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    if (password.value) {
      return {
        validateStatus: 'error',
        errorMsg: 'The confirmed password and the password do not match',
      };
    }
  }

  const onPasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword({ ...validatePassword(value), value });
  };

  const onConfirmPasswordChange = (event: any) => {
    const { value } = event.target;
    setConfirmPassword({ ...validateConfirmPassword(value), value });
  };

  const onFinish = (values: ResetPasswordParamsI) => {
    const newValues = values;
    const passwordValue = values.newPassword;
    const conformPasswordValue = values.confirmPassword;
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})');

    if (conformPasswordValue === passwordValue) {
      if (passwordValue) {
        if (strongRegex.test(passwordValue)) {
          handleSubmit(newValues);
        }
      } else {
        handleSubmit(newValues);
      }
    }
  };

  return (
    <div className="login-form-container">
      <div className="form-container">
        <div className="logo-section">
          <Link to="/">
            <ReactSVG src={LoginSVG} />
          </Link>
        </div>
        <h2 className="title">Reset Password</h2>
        <Form scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={onFinish}>
          <div className="FormContent">
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Required' }]}>
              <Input size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              validateStatus={password.validateStatus}
              help={password.errorMsg || passwordTips}
              className="password-input"
            >
              <Input.Password size="large" placeholder="Required" onChange={onPasswordChange} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              validateStatus={confirmPassword.validateStatus}
              help={confirmPassword.errorMsg}
              className="password-input"
            >
              <Input.Password size="large" placeholder="Required" onChange={onConfirmPasswordChange} />
            </Form.Item>
          </div>
          <div className="FormFooter">
            <PrimaryButton htmlType="submit" label="Send" style={{ width: '100%' }} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
