import { pathToUrl } from './../utils/router';
import { GetListResponse } from '../interfaces';
import { useFetch, usePost } from '../shared/reactQuery';
import { api } from '../shared/api';
import { handleFilterParams } from '../utils/global';
import { LicenseI } from '../interfaces/license';

export const useGetLicensesList = () => useFetch<GetListResponse<LicenseI>>('licenses');

export const useGetLicenseDetails = (id: number) => useFetch<LicenseI>(`licenses/${id}`);

export const useCreateLicense = (updater: (oldData: LicenseI[], newData: LicenseI) => LicenseI[]) => {
  return usePost<LicenseI[], LicenseI>(pathToUrl('licenses'), undefined, updater);
};

export const doGetLicensesList = async (params?: { start: number; count: number; filter?: any }) => {
  const newParams = handleFilterParams(params);
  const { data: response }: any = await api.get(`licenses?${newParams}`);
  return response;
};

export const doCreateLicense = async (values: LicenseI) => {
  const { data: response }: any = await api.post('licenses', values);
  return response.data;
};

export const doUpdateLicense = async ({ id, ...values }: { id: string; values: LicenseI }) => {
  const { data: response }: any = await api.patch(`licenses/${id}`, values);
  return response.data;
};

export const doDeleteLicense = async (id: number) => {
  const { data: response }: any = await api.delete(`licenses/${id}`);
  return response.data;
};
