import { message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { doCreateCustomer } from '../../api/customers';
import { CustomerI } from '../../interfaces/customer';
import CustomerForm from '../../shared/components/forms/customer/Form';

const CustomerNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(doCreateCustomer, {
    onSuccess: () => {
      message.info('Create customer successfully!');
      queryClient.invalidateQueries({
        queryKey: ['customers', 'list'],
      });
      navigate(`/customers`, { replace: true });
    },
    onError: () => {
      message.error('Create customer failed! Please try again');
    },
  });

  const handleSubmit = async (values: CustomerI) => {
    createMutate(values);
  };

  return (
    <div className="company-new-container">
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-header">
            <h2 className="title-page">Add New Customer</h2>
          </div>
          <CustomerForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default CustomerNewPage;
