import { Button, Dropdown, Spin, message, notification } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { QueryParams } from '../../interfaces';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import { PAGE_SIZE } from '../../shared/constants';
import AddCircleOutlineIcon from '../../shared/svgs/AddCircleOutlineIcon';
import './Salesheets.scss';
import * as qs from 'qs';
import {
  doDeleteSalesheet,
  doDuplicateSalesheet,
  doExportSalesheet,
  doExportSalesheetPdf,
  doGetSalesheetsList,
} from '../../api/salesheets';
import DangerButton from '../../shared/components/buttons/DangerButton';
import SalesheetTable from '../../shared/components/tables/Salesheets';
import { SalesheetI } from '../../interfaces/salesheet';
import ConfirmButton from '../../shared/components/buttons/ConfirmButton';
import CheckCircleIcon from '../../shared/svgs/CheckCircleIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/global';

const SalesheetsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles || [], 'salesheets');
  const navigate = useNavigate();
  const location = useLocation();
  const { search, pathname } = location;
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const pushTo = (pushProps: any) => {
    navigate(
      {
        pathname,
        search: qs.stringify(pushProps, { encode: false, skipNulls: true }),
      },
      { state: pushProps },
    );
  };

  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    start: 0,
    count: PAGE_SIZE,
  });

  const { data, isLoading }: any = useQuery(['salesheets', 'list', queryParams], () =>
    doGetSalesheetsList(queryParams),
  );

  const handleFilter = (params: { page: number }) => {
    const currentPage = params.page;
    const offset = currentPage ? (currentPage === 1 ? 0 : (Number(currentPage) - 1) * PAGE_SIZE) : 0;
    let newFilter: any = undefined;
    if (search) {
      const newSearch = search.replace('?', '');
      const oldSearch: any = qs.parse(newSearch);
      newFilter = oldSearch?.filter || undefined;
    }
    const newParams = { start: Number(offset), count: PAGE_SIZE, filter: newFilter };
    pushTo(newParams);
    setQueryParams(newParams);
  };

  const currentPage = queryParams.start ? Math.round((Number(queryParams.start) - 1) / PAGE_SIZE + 1) : 1;

  const pagination: any = {
    current: currentPage,
    pageSize: PAGE_SIZE,
    total: data?.total || 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const { mutateAsync: mutationDelete } = useMutation(doDeleteSalesheet, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['salesheets', 'list'],
      });
    },
  });

  const handleClickDelete = (record: SalesheetI) => {
    mutationDelete(record.id);
  };

  const { mutate: duplicateMutate } = useMutation(doDuplicateSalesheet, {
    onSuccess: async () => {
      message.info('Duplicate salesheet successfully!');
      queryClient.invalidateQueries({
        queryKey: ['salesheets', 'list'],
      });
      navigate(`/salesheets`, { replace: true });
    },
    onError: () => {
      message.error('Duplicate salesheet failed! Please try again');
    },
  });

  const { mutate: exportPdfMutate, isLoading: isDownloadingPdf } = useMutation(doExportSalesheetPdf, {
    onSuccess: async () => {
      message.info('Successfully!');
      notification.destroy(1);
    },
    onError: () => {
      message.error('Something went wrong! Please try again');
    },
  });

  const onDuplicateClick = (record: SalesheetI) => {
    duplicateMutate(record?.id || 0);
  };

  const { mutate: exportMutate, isLoading: isExportExcelLoading } = useMutation(doExportSalesheet, {
    onSuccess: async () => {
      message.info('Successfully!');
      notification.destroy(1);
    },
    onError: () => {
      message.error('Something went wrong! Please try again');
    },
  });

  const onSelectChange = async (record: any) => {
    setExportLoading(true);
    let params: any = { filmIds: [] };
    params = {
      ids: [record.id],
    };
    exportMutate(params);
    setTimeout(() => {
      setExportLoading(false);
    }, 1000);
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/salesheets/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              {
                key: '4',
                label: (
                  <Button
                    className="btn-table-action-primary"
                    type="link"
                    loading={isExportExcelLoading}
                    onClick={() => {
                      onSelectChange(record);
                      notification.open({ icon: <Spin />, message: 'Exporting your Excel', duration: 0, key: 1 });
                    }}
                  >
                    Export
                  </Button>
                ),
              },
              {
                key: '100',
                label: (
                  <Button
                    className="btn-table-action-primary"
                    type="link"
                    loading={isDownloadingPdf}
                    onClick={() => {
                      exportPdfMutate(record?.id);
                      notification.open({ icon: <Spin />, message: 'Exporting your PDF', duration: 0, key: 1 });
                    }}
                  >
                    Export as PDF
                  </Button>
                ),
              },
              access.isCanCreate
                ? {
                    key: '3',
                    label: (
                      <ConfirmButton
                        label="Duplicate"
                        className="btn-table-action-primary"
                        content={'Are you sure to duplicate this salesheet?'}
                        onOk={() => onDuplicateClick(record)}
                        confirmIcon={<CheckCircleIcon color="#009B40" />}
                      />
                    ),
                  }
                : null,
              access.isCanDelete
                ? {
                    key: '2',
                    label: <DangerButton label="Delete" onOk={() => handleClickDelete(record)} />,
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <div className="salesheets-container">
      <div className="page-container">
        <div className="page-header">
          <div></div>
          <div className="page-header-actions">
            {access.isCanCreate && (
              <Link to="/salesheets/new">
                <PrimaryButton label="Create Salesheet" icon={<AddCircleOutlineIcon />} />
              </Link>
            )}
          </div>
        </div>
        <div className="page-content">
          <SalesheetTable
            rowKey="id"
            loading={isLoading || exportLoading}
            pagination={pagination}
            dataSource={data?.data}
            withAction={withAction}
            onChange2={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default SalesheetsPage;
