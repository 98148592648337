import { DatePicker, Form, Input, Select, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT_FRONTEND, getSEACountries } from '../../../constants';
import SecondaryButton from '../../buttons/SecondaryButton';
import debounce from 'lodash/debounce';
import ClearAllIcon from '../../../svgs/ClearAllIcon';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from 'react-query';
import { getRightsList } from '../../../../api/rights';
import { RightI } from '../../../../interfaces/right';
import './Filter.scss';
import { useLocation } from 'react-router-dom';
import {
  convertDayJSToISOString,
  convertToRightTreeList,
  handleDisplayFilterFields,
  handleGetCountriesWithSEA,
  mergeUniqueStringArray,
  getCountriesList,
  getCountryLabel,
  getCountryValue,
} from '../../../../utils/global';
import { getSuppliersList } from '../../../../api/suppliers';
import { SupplierI } from '../../../../interfaces/supplier';

type Props = {
  handleFilter(_: any): void;
  handleReset(): void;
};

interface filterI {
  licenseStartDate?: number;
  licenseEndDate?: number;
  licensor?: string;
  title?: string;
  territories?: string[];
  rightIds?: number[];
  supplierId?: number;
}

const { RangePicker } = DatePicker;

const FilmsFilterForm: React.FC<Props> = ({ handleReset, handleFilter }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { search } = location;
  const newCountryList = useMemo(() => getCountriesList(true), []);
  const [filterFields, setFilterFields] = useState<filterI>();
  const [treeList, setTreeList] = useState<RightI[]>();
  const { data: rights, isLoading: isRightLoading } = useQuery(['rights', 'list'], () => getRightsList({}));
  const { data: suppliersList, isLoading: isSupplierLoading } = useQuery(['suppliers', 'list', {}], () =>
    getSuppliersList({}),
  );

  const handleOnFinish = debounce(async () => {
    handleFilter(filterFields);
  }, 800);

  const onLicenseDateChange = (dates: null | (Dayjs | null)[]) => {
    let licenseStartDate = 0;
    let licenseEndDate = 0;
    if (dates) {
      licenseStartDate = (dates[0] && dates[0].valueOf()) || 0;
      licenseEndDate = (dates[1] && dates[1].valueOf()) || 0;
    }
    setFilterFields({ licenseStartDate, licenseEndDate });
  };

  const onTitleChange = (event: any) => {
    const { value } = event.target;
    setFilterFields({ ...filterFields, title: value });
  };

  const onTerritoryChange = (values: string[]) => {
    let newCountryCodes: string[] = values;
    if (values.includes('SEA')) {
      const sea = getSEACountries;
      newCountryCodes = mergeUniqueStringArray(sea, values);
    }
    const newTerritories = newCountryCodes?.map((item: string) => getCountryLabel(newCountryList, item));
    setFilterFields({ ...filterFields, territories: newTerritories });
  };

  const onTerritoryRightChange = (value: number[]) => {
    setFilterFields({ ...filterFields, rightIds: value });
  };

  const onSupplierChange = (value: number) => {
    setFilterFields({ ...filterFields, supplierId: value });
  };

  const handleResetForm = () => {
    form.resetFields();
    handleReset();
    setFilterFields(undefined);
  };

  useEffect(() => {
    if (filterFields) {
      handleOnFinish();
    }
  }, [filterFields]);

  useEffect(() => {
    if (search) {
      const filterParams = handleDisplayFilterFields(search);
      let rightIds: number[] | undefined = undefined;
      if (filterParams?.rightIds) {
        rightIds = filterParams.rightIds.map((item: number) => Number(item));
      }
      let licenseDateRange: Dayjs[] | undefined = undefined;
      if (filterParams?.licenseStartDate && filterParams?.licenseEndDate) {
        const licenseStartDate = convertDayJSToISOString(dayjs(Number(filterParams.licenseStartDate)));
        const licenseEndDate = convertDayJSToISOString(dayjs(Number(filterParams.licenseEndDate)));
        licenseDateRange = [licenseStartDate, licenseEndDate];
      }
      let countryCodes: string[] | undefined = undefined;
      if (filterParams?.territories) {
        const newCountries = handleGetCountriesWithSEA(filterParams?.territories);
        countryCodes = newCountries.map((item: string) => getCountryValue(newCountryList, item) || item);
      }
      form.setFieldsValue({
        ...filterParams,
        territories: countryCodes,
        rightIds,
        licenseDateRange,
      });
      setFilterFields(filterParams);
    }
  }, []);

  useEffect(() => {
    if (rights?.data) {
      const newData = convertToRightTreeList(rights?.data);
      setTreeList(newData);
    }
  }, [rights?.data]);
  return (
    <div className="films-filter-form-container filter-form-container expand-filter">
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
      >
        <div className="form-container">
          <div className="form-content">
            <Form.Item name="licenseDateRange" label="Overall License Period">
              <RangePicker
                className="ant"
                format={DATE_FORMAT_FRONTEND}
                onChange={(dates) => onLicenseDateChange(dates)}
              />
            </Form.Item>
            <Form.Item name="title" label="Movie Title (English)">
              <Input placeholder="Movie Title (English)" onChange={onTitleChange} />
            </Form.Item>
            <Form.Item name="supplierId" label="Supplier">
              <Select
                placeholder="Supplier"
                showSearch
                optionFilterProp="children"
                onChange={onSupplierChange}
                allowClear
                loading={isSupplierLoading}
              >
                {suppliersList?.data?.map((item: SupplierI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="territories" label="Territory">
              <Select
                placeholder="Territory"
                showSearch
                optionFilterProp="label"
                options={newCountryList}
                onChange={onTerritoryChange}
                mode="multiple"
                allowClear
              />
            </Form.Item>
            <Form.Item label="Rights" name="rightIds">
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                placeholder="Rights"
                allowClear
                treeDefaultExpandAll
                onChange={onTerritoryRightChange}
                treeData={treeList}
                loading={isRightLoading}
                multiple={true}
              />
            </Form.Item>
          </div>
          <div className="form-footer">
            <SecondaryButton label={'Clear'} onClick={handleResetForm} icon={<ClearAllIcon />} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FilmsFilterForm;
