import React from 'react';
type Props = {
  width?: number;
  height?: number;
};

const ClearAllIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height || 24}px`}
      viewBox="0 0 24 24"
      width={`${width || 24}px`}
      fill="currentColor"
    >
      <g clipPath="url(#clip0_865_1272)">
        <path d="M5 13H19V11H5V13ZM3 17H17V15H3V17ZM7 7V9H21V7H7Z" fill="#009B40" />
      </g>
      <defs>
        <clipPath id="clip0_865_1272">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ClearAllIcon;
