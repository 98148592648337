import React from 'react';
import { FilmI } from '../../../interfaces/film';
import { DateFormat } from '../../../utils/global';
import TableBase, { Props as TableProps } from './Base';
import { CustomerI } from '../../../interfaces/customer';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const LicensesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Film',
      dataIndex: 'film',
      width: 150,
      render: (film: FilmI) => {
        return <span>{film?.title}</span>;
      },
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 150,
      render: (customer: CustomerI) => {
        return <span>{customer?.name}</span>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: 150,
      ellipsis: true,
      render: (value: string) => {
        return <span>{DateFormat(value)}</span>;
      },
    },
    {
      title: 'Contract Reference Number',
      dataIndex: 'contractReferenceNumber',
      width: 150,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      width: 100,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default LicensesTable;
