import { message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { doDeleteRight, useGetRightDetails } from '../../api/rights';
import DangerButton from '../../shared/components/buttons/DangerButton';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import CancelIcon from '../../shared/svgs/CancelIcon';
import DeleteForeverIcon from '../../shared/svgs/DeleteForeverIcon';
import EditIcon from '../../shared/svgs/EditIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { getUsername, handleAccessControl } from '../../utils/global';
import BackButton from '../../shared/components/buttons/BackButton';
import { DATE_FORMAT_FRONTEND } from '../../shared/constants';
import dayjs from 'dayjs';

const RightDetailsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser.roles, 'rights');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: right, isLoading } = useGetRightDetails(Number(id));

  const { mutateAsync: mutationDelete } = useMutation(doDeleteRight, {
    onSuccess: async () => {
      message.info('Deleted!');
      queryClient.invalidateQueries({
        queryKey: ['rights', 'list'],
      });
      navigate(`/rights`, { replace: true });
    },
  });

  const handleDelete = () => {
    mutationDelete(Number(id));
  };

  return (
    <Spin spinning={isLoading}>
      <div id="right-details-container">
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-header">
              <h2 className="title-page">Right Details</h2>
            </div>
            <div className="details-container">
              <div className="item">
                <label>Name</label>
                <span className="value">{right?.name}</span>
              </div>
              <div className="item">
                <label>Parent</label>
                <span className="value">{right?.parent?.name}</span>
              </div>
              <div className="item">
                <label>Created At</label>
                <span className="value">{dayjs(right?.createdAt).format(DATE_FORMAT_FRONTEND)}</span>
              </div>
              <div className="item">
                <label>Created By</label>
                <span className="value">{right?.createdBy ? getUsername(right?.createdBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Updated At</label>
                <span className="value">
                  {right?.updatedBy ? dayjs(right?.updatedAt).format(DATE_FORMAT_FRONTEND) : '-'}
                </span>
              </div>
              <div className="item">
                <label>Updated By</label>
                <span className="value">{right?.updatedBy ? getUsername(right?.updatedBy) : '-'}</span>
              </div>
              <div className="item">
                <label>Remarks</label>
                <span className="value">{right?.remarks}</span>
              </div>
            </div>
            <div className="page-content-footer">
              {access.isCanUpdate && (
                <Link to={`/rights/${id}/edit`}>
                  <PrimaryButton label="Edit" icon={<EditIcon />} />
                </Link>
              )}
              {access.isCanDelete && <DangerButton label="Delete" onOk={handleDelete} icon={<DeleteForeverIcon />} />}
              <BackButton label="Cancel" icon={<CancelIcon />} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default RightDetailsPage;
